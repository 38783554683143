import React, { useState } from "react";
import BodyStyles from "../../ShowServisces/ServicePagesParts/servicePagesParts.module.scss";
import { Flex } from "antd";
import MarqueeText from "../../ShowServisces/ServicePagesParts/AnimatedPart/AnimatedPart";
import ApplicationsPart from "../../ShowServisces/ServicePagesParts/SubBodyParts/ApplicationsPart";
import ResultTable from "./SubBodyParts/Formpart";
export default function BodyPart({ newsRecords }) {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <Flex vertical={true} gap={12} className={BodyStyles.body_part}>
      <div style={{ width: "100%" }}>
        <MarqueeText isClicked={isClicked} setIsClicked={setIsClicked} />
      </div>
      <Flex vertical={false} gap={18} align="flex-start">
        <ApplicationsPart newsRecords={newsRecords} />
        <ResultTable />
      </Flex>
    </Flex>
  );
}
