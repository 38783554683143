import React from "react";
import styles from "./AdmitForm.module.scss";
import { ConfigProvider, Flex, Input, Select } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  getDateArrayForSelectComponent,
  getMonthArrayForSelectComponent,
  getYearArrayForSelectComponent,
} from "../../../Helpers/helpers";

const DateOptions = getDateArrayForSelectComponent();
const YearOptions = getYearArrayForSelectComponent();
const MonthOptions = getMonthArrayForSelectComponent();
export default function AdmitForm() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            activeBorderColor: "#000",
            activeOutlineColor: "#000",
          },
        },
        token: {
          colorTextPlaceholder: "#3a3939",
          controlOutlineWidth: 1,
        },
      }}
    >
      <Flex vertical={true} gap={12} className={styles.admitform_div}>
        <Flex vertical={false} align="center" className={styles.form_header}>
          <span>DOWNLOAD YOUR ADMIT CARD:</span>
        </Flex>
        <Flex
          vertical={false}
          align="center"
          justify="space-between"
          wrap={true}
          style={{
            width: "100%",
            paddingRight: "12px",
            boxSizing: "border-box",
          }}
        >
          <Flex vertical={true} gap={4} className={styles.form_container}>
            <Flex vertical={false} gap={8} wrap={true}>
              <span className={styles.form_container__text}>
                Enrolment No :
              </span>
              <Input
                placeholder="Enrolment No"
                className={styles.form_container__input}
              />
              <span className={styles.form_container__text}>& DOB :</span>
              <Flex vertical={false} gap={4} wrap={true}>
                <Select
                  placeholder="Date"
                  style={{
                    width: 90,
                  }}
                  options={DateOptions}
                  onChange={() => {}}
                  suffixIcon={<DownOutlined style={{ color: "black" }} />}
                />
                <Select
                  placeholder="Month"
                  style={{
                    width: 90,
                  }}
                  options={MonthOptions}
                  onChange={() => {}}
                  suffixIcon={<DownOutlined style={{ color: "black" }} />}
                />
                <Select
                  placeholder="Year"
                  style={{
                    width: 90,
                  }}
                  options={YearOptions}
                  onChange={() => {}}
                  suffixIcon={<DownOutlined style={{ color: "black" }} />}
                />
              </Flex>
            </Flex>
            <span className={styles.form_container__text}>OR</span>
            <Flex vertical={false} gap={8} wrap={true}>
              <span className={styles.form_container__text}>FIRST NAME :</span>
              <Input
                placeholder="FIRST NAME"
                className={styles.form_container__input}
              />
              <span className={styles.form_container__text}>& DOB :</span>
              <Flex vertical={false} gap={4} wrap={true}>
                <Select
                  placeholder="Date"
                  style={{
                    width: 90,
                  }}
                  options={DateOptions}
                  onChange={() => {}}
                  suffixIcon={<DownOutlined style={{ color: "black" }} />}
                />
                <Select
                  placeholder="Month"
                  style={{
                    width: 90,
                  }}
                  options={MonthOptions}
                  onChange={() => {}}
                  suffixIcon={<DownOutlined style={{ color: "black" }} />}
                />
                <Select
                  placeholder="Year"
                  style={{
                    width: 90,
                  }}
                  options={YearOptions}
                  onChange={() => {}}
                  suffixIcon={<DownOutlined style={{ color: "black" }} />}
                />
              </Flex>
            </Flex>
          </Flex>
          <button className={styles.submit_button}>Submit</button>
        </Flex>
      </Flex>
    </ConfigProvider>
  );
}
