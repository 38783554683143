import React from "react";
import AnnouncementStyles from "../servicePagesParts.module.scss";
import { Flex } from "antd";

function ShowdataBox({ text, link }) {
  return (
    <Flex className={AnnouncementStyles.announcement_box}>
      <span>
        <span>» </span>
        <a
          href={link}
          style={{
            textDecoration: "underline",
            color: "inherit",
            cursor: "pointer"
          }}
          onMouseEnter={(e) => e.target.style.textDecoration = "underline"}
          onMouseLeave={(e) => e.target.style.textDecoration = "underline"}
        >
          {text}
        </a>

        <img src="/new11.gif" alt="new_gif" width={30} height={14} />
      </span>
    </Flex>
  );
}
export default function AnnouncementPart({ data }) {
  return (
    <Flex vertical={true} className={AnnouncementStyles.announcement_part}>
      <div className={AnnouncementStyles.announcement_header}>
        <img
          src="/images/announceArrow.png"
          alt="arrow"
          width={18}
          height={23}
        />
        <span>
          <b>Latest Advertisements/Announcement</b>
        </span>
      </div>
      {data.map((item, index) => {
        return <ShowdataBox key={index} text={item.content} link={item.link} />;
      })}
    </Flex>
  );
}
