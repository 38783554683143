export const portals = [
  {
    id: 1,
    label: "LIVE Application",
    path: "#",
  },
  {
    id: 2,
    label: "Proactive Disclosure",
    path: "#",
  },
  {
    id: 3,
    label: "Answer Key Response",
    path: "#",
  },
];

export const Dummynews = [
  {
    id: 1,
    content:
      "JUNIOR ENGINEERS (CIVIL) RECRUITMENT EXAMINATION, 2022 (Advertisement No.9/2022)",
    link: "/",
  },
  {
    id: 2,
    content:
      "JUNIOR ENGINEERS (MECHANICAL) RECRUITMENT EXAMINATION, 2022 (Advertisement No. 9/2022)",
    link: "/",
  },
  {
    id: 3,
    content:
      "JUNIOR ENGINEERS (ELECTRICAL) RECRUITMENT EXAMINATION, 2022 (Advertisement No. 9/2022)",
    link: "/",
  },
  {
    id: 4,
    content:
      "ASSISTANT PROFESSOR IN CIVIL ENGINEERING FOR GOVERNMENT ENGINEERING AND TECNOLOGICAL COLLEGES IN W.B.G.S UNDER THE DEPARTMENT OF HIGHER EDUCATION, GOVERNMENT OF WEST BENGAL.(Advertisement NO. 7(2)/2021)",
    link: "/",
  },

  {
    id: 5,
    content:
      "ASSISTANT PROFESSOR IN ELECTRICAL ENGINEERING FOR GOVT. ENGINEERING & TECHNOLOGICAL COLLEGESIN W.B.G.S. UNDER THE DEPARTMENT OF HIGHER EDUCATION, GOVT. OF W.B Advertisement No. 7(4)/2021",
    link: "/",
  },
  {
    id: 6,
    content:
      "ASSISTANT PROFESSOR IN ELECTRONICS & COMMUNICATION ENGINEERING FOR GOVT. ENGINEERING & TECHNOLOGICAL COLLEGESIN W.B.G.S. UNDER THE DEPARTMENT OF HIGHER EDUCATION, GOVT. OF W.B Advertisement No. 7(5)/2021",
    link: "/",
  },
  {
    id: 7,
    content:
      "ASSISTANT PROFESSOR IN MECHANICAL ENGINEERING FOR GOVT. ENGINEERING & TECHNOLOGICAL COLLEGESIN W.B.G.S. UNDER THE DEPARTMENT OF HIGHER EDUCATION, GOVT. OF W.B Advertisement No. 7(8)/2021",
    link: "/",
  },
  {
    id: 8,
    content:
      " Deputy Director (Engineering) in River Research Institute under the Irrigation & Waterways Department, Govt. of W.B. vide (Advt. No. 08/2022)",
    link: "/",
  },
];
