import { Flex, Form, Input} from "antd";
import React from "react";
import Styles from "./Admin.module.scss";
import { toaddNewStudentData, todeleteStudentData } from "../../Helpers/query/queryFunctions";
export default function Adminpage() {
  const [NewStudentFrom] = Form.useForm();
  const [DeleteForm] = Form.useForm();
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

  const handleSaveClick = async () => {
    try {
      const formdata = await NewStudentFrom.validateFields();
      const response = await toaddNewStudentData(formdata);
      if (response.status === 200) {
        alert("Data saved successfully");
        NewStudentFrom.resetFields();
      }
    }
    catch (err) {
      console.error(err.message);
    }
  }

  const handleDeleteClick = async () => {
    try {
      const formdata = await DeleteForm.validateFields();
      const response = await todeleteStudentData(formdata.roll);
      if (response.status === 200) {
        alert("Data deleted successfully");
        DeleteForm.resetFields();
      }
    }
    catch (err) {
      console.error(err.message);
    }
  }
  return (
    <Flex
      vertical={true}
      gap={10}
      align="center"
      justify="center"
      className={Styles.admin_container}
    >
      <h1>Admin portal</h1>
      <Flex vertical={true} gap={20} align="center" justify="center" style={{ width: "100%" }}>
        <Form form={NewStudentFrom} className={Styles.card_styles}>
          <Flex vertical={true} gap={20} >
            <Flex vertical={true}>
              <span>Candidate roll*</span>
              <Form.Item name="roll" rules={[{ required: true, message: "Please input candidate's roll!" }]} style={{ margin: "0px" }}>
                <Input placeholder="Candidate roll" width={"100%"} />
              </Form.Item>
            </Flex>
            <Flex vertical={true}>
              <span>Candidate name*</span>
              <Form.Item name="name" rules={[{ required: true, message: "Please input candidate's name!" }]} style={{ margin: "0px" }}>
                <Input placeholder="Candidate name" width={"100%"} />
              </Form.Item>
            </Flex>
            <Flex vertical={true}>
              <span>Father/Husband Name* </span>
              <Form.Item name="fatherName" rules={[{ required: true, message: "Please input candidate's father or husband name!" }]} style={{ margin: "0px" }}>
                <Input placeholder="Candidate name" width={"100%"} />
              </Form.Item>
            </Flex>
            <Flex vertical={true} gap={4}>
              <span>Candidate post*</span>
              <Form.Item name="post" rules={[{ required: true, message: "Please input candidate's post!" }]} style={{ margin: "0px" }}>
                <Input placeholder="Candidate post" width={"100%"} />
              </Form.Item>
            </Flex>

            <Flex vertical={true} gap={4}>
              <span>DOB*</span>
              <Form.Item
                name="dob"
                rules={[
                  {
                    required: true,
                    message: "Please input candidate's DOB!",
                  },
                  {
                    pattern: dateRegex,
                    message: "Invalid date format (yyyy-mm-dd)",
                  },
                ]}
                style={{ margin: "0px" }}
              >
                <Input placeholder="Candidate DOB (yyyy-mm-dd)" width={"100%"} />
              </Form.Item>
            </Flex>

            <Flex vertical={true} gap={4}>
              <span>Remarks*</span>
              <Form.Item name="remarks" rules={[{ required: true, message: "Please input candidate's remarks!" }]} style={{ margin: "0px" }}>
                <Input placeholder="Candidate's remarks" width={"100%"} />
              </Form.Item>
            </Flex>
            <button className={Styles.submit_button} onClick={handleSaveClick}>Save</button>
          </Flex>
        </Form>

        <Form form={DeleteForm} className={Styles.card_styles}>
          <Flex vertical={true} gap={20}>
            <Flex vertical={true} gap={4}>
              <span>Candidate name*</span>
              <Form.Item name="name" rules={[{ required: true, message: "Please input candidate's name!" }]} style={{ margin: "0px" }}>
                <Input placeholder="Candidat name" width={"100%"} />
              </Form.Item>
            </Flex>
            <Flex vertical={true} gap={4}>
              <span>Candidate roll*</span>
              <Form.Item name="roll" rules={[{ required: true, message: "Please input candidate's roll!" }]} style={{ margin: "0px" }}>
                <Input placeholder="Candidate roll" width={"100%"} />
              </Form.Item>
            </Flex>
            <button className={Styles.submit_button} onClick={handleDeleteClick}>Delete</button>
          </Flex>
        </Form>
      </Flex>
    </Flex>
  );
}
