export const Announcements = [
  {
    id: 1,
    content:
      "LIST OF CANDIDATES RECOMMENDED FOR APPOINTMENT TO THE POST OF ICDS WORKER, HELPER AND SUPERVISOR (FEMALE ONLY) UNDER WOMEN & CHILD DEVELOPMENT & SOCIAL WELFARE DEPARTMENT, GOVT. OF W.B. ON THE BASIS OF THE MERIT LIST OF ICDS WORKER, HELPER AND SUPERVISOR (FEMALE ONLY) MEMO NO:70/ICDS(OM)2024",
    link: "/my-pdf/ICDS_Worker_List.pdf",
  },
  {
    id: 2,
    content:
      "LIST OF CANDIDATES RECOMMENDED FOR APPOINTMENT TO THE POST OF ICDS SUPERVISOR (FEMALE ONLY) UNDER WOMEN & CHILD DEVELOPMENT & SOCIAL WELFARE DEPARTMENT, GOVT. OF W.B. ON THE BASIS OF THE RESULTS OF ICDS SUPERVISOR (FEMALE ONLY) RECRUITMENT EXAMINATION, 2019. (ADVT. NO. 08/2019)",
    link: "/my-pdf/list_icds_19.pdf",
  },
  {
    id: 3,
    content:
      "LIST OF CANDIDATES RECOMMENDED FOR APPOINTMENT TO THE POSTS OF LDA/LDC OR POSTS SIMILAR TO THAT OF LDA/LDC ON THE BASIS OF THE RESULTS OF THE CLERKSHIP EXAMINATION, 2019 AGAINST REGIONAL VACANCIES",
    link: "/my-pdf/RECOMMENDED-CANDIDATES-RESULTS-OF-KRISHI-PRAYUKTI-SAHAYAK-RECTT-EXAM...pdf",
  },
  {
    id: 4,
    content:
      "List of 8126 candidates called for Personality Test on the basis of the results of Written Test (Part-II) for rectt. to the posts of Supervisor (Female only) of ICDS, 2019 under the Women & Child Development & Social Welfare Dept., Govt of W.B. (Advt. No. 8/2019)",
    link: "/my-pdf/ICDSResult.pdf",
  },
  {
    id: 5,
    content:
      "LIST OF CANDIDATES RECOMMENDED FOR APPOINTMENT TO THE POSTS OF LDA/LDC OR POSTS SIMILAR TO THAT OF LDA/LDC IN DIFFERENT SECRETARIAT & DIRECTORATE (OFFICES ON THE BASIS OF THE RESULTS OF THE CLERKSHIP EXAMINATION,2019 (ADVT. NO.05/2019)",
    link: "/my-pdf/20220615173608_Result.pdf",
  },
  {
    id: 6,
    content:
      "ANSWER KEYS TO THE ENGLISH & GENERAL AWARENESS PAPER OF THE ASSISTANT PROFESSOR RECTT. EXAMINATION (Advt.No-29(1)/2019)",
    link: "/my-pdf/202205201657_AnswerKeys.pdf",
  },
  {
    id: 7,
    content:
      "IMPORTANT ANNOUNCEMENT REGARDING CLERKSHIP EXAMINATION, 2019 [ADVT. NO. 5/2019]",
    link: "/my-pdf/Notice Clerkship 26-4-22.pdf",
  },
  {
    id: 8,
    content:
      "IMPORTANT NOTICE REGARDING CLERKSHIP EXAMINATION, 2019 (ADVERTISEMENT NO. 05/2019) TYPING TEST",
    link: "/my-pdf/List clerkship_merged.pdf",
  },
  {
    id: 9,
    content:
      "IMPORTANT ANNOUNCEMENT AND CHOICE SHEET OF W.B.C.S. (EXECUTIVE) ETC. EXAMINATION, 2019 GROUP D SERVICES AND POSTS",
    link: "/my-pdf/An_20211207141623_ICA_Advertisement1.pdf",
  },
  {
    id: 10,
    content: "RESULT OF CLERKSHIP EXAMINATION 2019 (Advertisement No. 05/2019)",
    link: "/my-pdf/clerk ship 2019 result.pdf",
  },
  {
    id: 11,
    content: "IMPORTANT NOTICE REGARDING CLERKSHIP EXAMINATION (05/2019)",
    link: "/my-pdf/Clerkship-2.pdf",
  },
  {
    id: 12,
    content:
      "IMPORTANT NOTICE IN CONNECTION WITH POSTPONEMENT OF WRITTEN EXAMINATIONS.",
    link: "/my-pdf/An_20210427163705_PostponementNotice.pdf",
  },
  {
    id: 13,
    content:
      "IMPORTANT NOTICE REGARDING MISCELLANEOUS SERVICES RECRUITMENT EXAM. 2018 (ADVT NO. 29/2017)",
    link: "/my-pdf/An_20210429192032_NoticeMSRE.pdf",
  },
  {
    id: 14,
    content:
      "IMPORTANT NOTICE REGARDING ONLINE INTERVIEW OF WEST BENGAL CIVIL SERVICES (EXE.) ETC. EXAMINATION, 2019 (FOR BOTH GROUP 'A' AND 'B' SERVICES)",
    link: "/",
  },
  {
    id: 15,
    content:
      "Schedule of Interview for recruitment to the post of Assistant Town Planner under Urban Development and Municipal Affairs Department, Govt. of West Bengal vide Commission's Advt. no. 26/2019",
    link: "/my-pdf/interview_schedule_ATP.pdf",
  },
  {
    id: 16,
    content: "OMR SHEET ICW RECRUITMENT OF FIRE OPERATORS (ADVT NO 15/2018)",
    link: "/",
  },
  {
    id: 17,
    content:
      "DETAILED RESULT OF 5376 CANDIDATES QUALIFIED FOR PERSONALITY TEST IN CONNECTION WITH RECRUITMENT TO THE POST OF FIRE OPERATOR UNDER THE DEPARTMENT OF FIRE AND EMERGENCY SERVICES (ADVT. NO. 15/2018)",
    link: "/my-pdf/15_2018_r_f.pdf",
  },
  {
    id: 18,
    content:
      "ROLL NUMBERS OF QUALIFIED CANDIDATES CALLED FOR PERSONALITY TEST, WEST BENGAL WORKS ACCOUNTANT RECRUITMENT EXAMINATION, 2018 (ADVT. NO. 23/2018)",
    link: "/my-pdf/23_2018_pre_r.pdf",
  },
  {
    id: 19,
    content:
      "IMPORTANT ANNOUNCEMENT REGARDING SCHEDULE OF FORTHCOMING INTERVIEW (ADVERTISEMENT NO. 8(III)/2017, 25(6)/2019, 25(7)/2019, 34/2019, 24/2019)",
    link: "/my-pdf/INT.pdf",
  },
  {
    id: 20,
    content:
      "ROLL NUMBERS OF QUALIFIED CANDIDATES CALLED FOR PERSONALITY TEST FOR GROUP-A SERVICE, WEST BENGAL CIVIL SERVICE (EXE.) ETC. EXAMINATION, 2019 (ADVT. NO. 29/2018)",
    link: "/my-pdf/WBCS_Gr_A__29_2018.pdf",
  },
  {
    id: 21,
    content:
      "ROLL NUMBERS OF QUALIFIED CANDIDATES CALLED FOR PERSONALITY TEST FOR GROUP-B SERVICE, WEST BENGAL CIVIL SERVICE (EXE.) ETC. EXAMINATION, 2019 (ADVT. NO. 29/2018)",
    link: "/",
  },
  {
    id: 22,
    content:
      "SCHEDULE OF INTERVIEW FOR RECTT. TO THE POST OF SUB-DIVISIONAL INFORMATION AND CULTURAL OFFICER, IN THE WEST BENGAL SUB-ORDINATE INFORMATION AND CULTURAL SERVICE UNDER THE INFORMATION AND CULTURAL AFFAIRS DEPARTMENT, GOVT. OF W.B. [ADVT. NO. 25/2018]",
    link: "/",
  },
  {
    id: 23,
    content:
      "IMPORTANT ANNOUNCEMENT REGARDING THE SCHEDULE OF FORTHCOMING INTERVIEW FOR THE POST OF SUB-DIVISIONAL INFORMATION AND CULTURAL OFFICER, IN THE WEST BENGAL SUB-ORDINATE INFORMATION AND CULTURAL SERVICE UNDER THE INFORMATION AND CULTURAL AFFAIRS DEPARTMENT, GOVT. OF W.B. [ADVT. NO. 25/2018]",
    link: "/",
  },
  {
    id: 24,
    content:
      "NAME OF THE CANDIDATES RECOMMENDED FOR APPOINTMENT TO THE POST OF DISTRICT INSPECTOR/ADDITIONAL DISTRICT INSPECTOR OF SCHOOLS. ADVT. NO. 9(1)/2018",
    link: "/",
  },
  {
    id: 25,
    content:
      "IMPORTANT ANNOUNCEMENT REGARDING THE SCHEDULE OF FORTHCOMING INTERVIEW",
    link: "/",
  },
  {
    id: 26,
    content: "IMPORTANT ANNOUNCEMENT REGARDING UPCOMING EXAMINATIONS.",
    link: "/",
  },
  {
    id: 27,
    content:
      "SCHEDULE OF INTERVIEW FOR RECTT. TO THE POST OF PHARMACIST GRADE - III / PHARMACIST-CUM-SALESMAN -GRADE - III IN THE DIRECTORATE OF E.S.I. (M.B.) SCHEME UNDER THE LABOUR DEPARTMENT, GOVT. OF W.B. [ADVT. NO.: 21/2019]",
    link: "/",
  },
  {
    id: 28,
    content:
      "INTERVIEW SCHEDULE (PHASE - IV) OF MISCELLANEOUS SERVICES RECRUITMENT EXAMINATION 2018 (ADVT. 29/2017)",
    link: "/",
  },
  {
    id: 29,
    content:
      " ROLL NUMBERS OF ELIGIBLE CANDIDATES TO BE CALLED TO INTERVIEW FOR RECRUITMENT TO THE POST OF LIBRARIAN IN THE GOVT. ENGINEERING & TECHNOLOGICAL COLLEGES IN W.B.G.S. UNDER THE DEPARTMENT OF HIGHER EDUCATION, GOVT. OF W.B. AGAINST COMMISSION'S ADVT. NO. 17(2)/2019",
    link: "/",
  },
  {
    id: 30,
    content:
      "LIST OF CANDIDATES SELECTED FOR PERSONALITY TEST OF LIMITED DEPARTMENTAL EXAMINATION FOR PROMOTION TO THE POSTS IN THE W.B. AUDIT AND ACCOUNTS SERVICE, 2019 (ADVERTISEMENT NO. 18/2019)",
    link: "/",
  },
  {
    id: 31,
    content:
      "LIST OF QUALIFIED CANDIDATES TO BE CALLED FOR INTERVIEW FOR RECTT. TO THE POST OF LIBRARIAN IN THE GOVT. COLLEGES UNDER THE DEPTT. OF HIGHER EDUCATION, GOVT. OF W.B. AGAINST ADVT. NO. - 23/2019",
    link: "/",
  },
  {
    id: 32,
    content:
      "LIST OF CANDIDATES TO BE CALLED TO INTERVIEW FOR RECRUITMENT TO THE POSTS OF OFFICERS BELONGING TO CATEGORY 'A' IN WEST BENGAL INFORMATION & CULTURAL SERVICE UNDER THE DEPARTMENT OF INFORMATION & CULTURAL AFFAIRS, GOVT. OF WEST BENGAL VIDE COMMISSION'S ADVT. NO. 9(3)/2019",
    link: "/",
  },
  {
    id: 33,
    content:
      "Result of all 957 recommended candidates for rectt. to the post of the Subordinate Food & Supplies Service, Grade-III, under Food & Supplies Department, Govt. of West Bengal, 2018 (Advertisement No. 26/2018).",
    link: "/",
  },
  {
    id: 34,
    content:
      "Result of all 3024 candidates (qualified for interview)for rectt. to the post of Subordinate Food & Supplies Service, Grade-III, under Food & Supplies Department, Govt. of West Bengal, 2018 (Advertisement No. 26/2018).",
    link: "/",
  },
  {
    id: 35,
    content:
      "LIST OF THE CANDIDATES RECOMMENDED FOR RECRUITMENT TO THE POST OF FIRE OPERATOR IN THE WEST BENGAL FIRE SERVICES UNDER THE DEPTT. OF FIRE AND EMERGENCY SERVICES, GOVT. OF WEST BENGAL AGAINST ADVT. NO. 15/2018",
    link: "/",
  },
  {
    id: 36,
    content:
      "LIST OF QUALIFIED CANDIDATES TO BE CALLED FOR INTERVIEW FOR RECTT TO THE POST OF AGRICULTURAL MARKETING OFFICER (TRAINING & CANNING) UNDER W.B. JUNIOR AGRICULTURAL SERVICE(MARKETING) DEPTT., GOVT. OF W.B. AGAINST ADVT. NO. - 14/2019",
    link: "/",
  },
  {
    id: 37,
    content:
      "LIST OF QUALIFIED CANDIDATES TO BE CALLED FOR RECTT. TO THE POST OF SCIENTIFIC ASSISTANT (CHEMICAL) UNDER THE DEPTT. OF LABOUR, GOVT. OF W.B. AGAINST ADVT. NO. - 25(1)/2019",
    link: "/",
  },
  {
    id: 38,
    content:
      "LIST OF QUALIFIED CANDIDATES TO BE CALLED FOR INTERVIEW FOR RECTT. TO THE POST OF WELFARE OFFICER IN CORRECTIONAL HOMES OF W.B. UNDER THE DEPTT. OF CORRECTIONAL ADMINISTRATION, GOVT. OF W.B. AGAINST ADVT. NO. - 31/2019",
    link: "/",
  },
  {
    id: 39,
    content:
      "LIST OF QUALIFIED CANDIDATES TO BE CALLED FOR INTERVIEW FOR RECTT. TO THE POST OF LABORATORY ATTENDANT UNDER THE LABOUR DEPTT., GOVT. OF W.B. AGAINST ADVT. NO. - 25(7)/2019",
    link: "/",
  },
  {
    id: 40,
    content:
      "LIST OF QUALIFIED CANDIDATES TO BE CALLED FOR INTERVIEW FOR RECTT. TO THE POST OF BOTANIST IN THE W.B.G.S.IN THE DIRECTORATE OF CINCHONA AND OTHER MEDICINAL PLANTS UNDER THE DEPTT. OF FOOD PROCESSING INDUSTRIES & HORTICULTURAL, GOVT. OF W.B. AGAINST ADVT. NO. - 19/2019",
    link: "/",
  },
  {
    id: 41,
    content:
      "LIST OF QUALIFIED CANDIDATES TO BE CALLED FOR INTERVIEW FOR RECTT. TO THE POST OF FITTER HELPER UNDER THE LABOUR DEPTT., GOVT. OF W.B. AGAINST ADVT. NO. - 25(6)/2019",
    link: "/",
  },
  {
    id: 42,
    content:
      "LIST OF QUALIFIED CANDIDATES TO BE CALLED FOR INTERVIEW FOR RECTT. TO THE POST OF DIALYSIS TECHNICIAN GRADE-III IN THE DIRECTORATE OF E.S.I.(M.B.) SCHEME UNDER THE LABOUR DEPTT., GOVT. OF W.B. AGAINST ADVT. NO. - 15/2019",
    link: "/",
  },
  {
    id: 43,
    content:
      "LIST OF QUALIFIED CANDIDATES TO BE CALLED FOR INTERVIEW FOR RECTT. TO THE POST OF UDYAN PALAN PROJUKTI SAHAYAK UNDER THE DEPTT. OF FOOD PROCESSING INDUSTRIES & HORTICULTURE, GOVT. OF W.B. AGAINST ADVT. NO. - 35/2019",
    link: "/",
  },
  {
    id: 44,
    content:
      "ROLL NUMBER OF QUALIFIED CANDIDATES TO BE CALLED FOR INTERVIEW FOR RECTT. TO THE POST OF MOTOR VEHICLES INSPECTOR(NON TECHNICAL) UNDER THE TRANSPORT DEPTT., GOVT. OF W.B. AGAINST ADVT. NO. - 32/2019",
    link: "/",
  },
  {
    id: 45,
    content:
      "CANDIDATES RECOMMENDED FOR RECRUITMENT TO THE POST OF ASSISTANT TOURIST OFFICER IN THE DIRECTORATE OF TOURISM UNDER THE TOURISM DEPARTMENT, GOVERNMENT OF WEST BENGAL AGAINST COMMISSION'S ADVT. NO. 7/2019.",
    link: "/",
  },
  {
    id: 46,
    content:
      "ROLL NUMBER OF THE CANDIDATES ELIGIBLE FOR INTERVIEW FOR RECRUITMENT TO THE POST OF SUB-DIVISIONAL INFORMATION AND CULTURAL OFFICER IN THE WEST BENGAL SUB-ORDINATE INFORMATION AND CULTURAL SERVICE UNDER THE INFORMATION AND CULTURAL AFFAIRS DEPARTMENT, GOVT. OF WEST BENGAL.(ADVT. NO-25/2018)",
    link: "/",
  },
  {
    id: 47,
    content:
      "NAME OF CANDIDATE RECOMMENDED FOR RECRUITMENT TO 1 (ONE) TEMPORARY BUT LIKELY TO BE PERMANENT POST [AGAINST UNRESERVED VACANCY] OF LECTURER IN 3D ANIMATION AND GRAPHICS IN GOVT. POLYTECHNICS IN THE W.B.G.S. UNDER THE DEPTT. OF TECHNICAL EDUCATION. TRAINING AND SKILL DEVELOPMENT GOVT. OF WEST BENGAL. (ADVT. NO. 24(XIV)/2018)",
    link: "/",
  },
  {
    id: 48,
    content:
      "FINAL ANSWER KEYS OF UDYAN PALAN PROJUKTI SAHAYAK (ADVERTISEMENT NO.35/2019)",
    link: "/",
  },
  {
    id: 49,
    content:
      "FINAL ANSWER KEYS OF ASSISTANT ENGINEER (CIVIL) RECRUITMENT EXAMINATION, 2020 (ADVERTISEMENT NO.13/2020)",
    link: "/",
  },
  {
    id: 50,
    content:
      "NAME OF THE CANDIDATES RECOMMENDED FOR APPOINTMENT TO 2 (TWO) TEMPORARY BUT LIKELY TO BE PERMANENT UNRESERVED POSTS OF LECTURER IN ELECTRONICS & INSTRUMENTATION ENGINEERING IN GOVT. POLYTECHNICS IN W.B.G.S UNDER THE DEPTT. OF TECHNICAL EDUCATION, TRAINING & SKILL DEVELOPNRENT, GOVT. OF W.B. AGAINST COMMISSION?S ADVT. NO. 24(VII)/2018",
    link: "/",
  },
  {
    id: 51,
    content:
      "SCHEDULE OF INTERVIEW FOR RECRUITMENT TO THE POSTS OF ASSISTANT TOURIST OFFICER IN THE DIRECTORATE OF TOURISM UNDER THE TOURISM DEPARTMENT, GOVT. OF WEST BENGAL ADVT. NO.: 7/2019",
    link: "/",
  },
  {
    id: 52,
    content:
      "IMPORTANT ANNOUNCEMENT REGARDING SCHEDULE OF FORTHCOMING INTERVIEW OF ASSISTANT TOURIST OFFICER IN THE DIRECTORATE OF TOURISM UNDER THE TOURISM DEPARTMENT, GOVT. OF WEST BENGAL (ADVT. NO. 7/2019)",
    link: "/",
  },
  {
    id: 53,
    content:
      "IMPORTANT ANNOUNCEMENT REGARDING RECRUITMENT TO THE POST OF SUB INSPECTOR IN SUBORDINATE FOOD AND SUPPLIES SERVICE GR.III UNDER FOOD AND SUPPLIES DEPTT., GOVT. OF WEST BENGAL, AGAINST (ADVT. NO. 26/2018)",
    link: "/",
  },
  {
    id: 54,
    content:
      "LIST OF CANDIDATES RECOMMENDED FOR APPOINTMENT IN THE WEST BENGAL LEGAL SERVICE ON THE BASIS OF THE RESULTS OF THE W.B. LEGAL SERVICE EXAMINATION, 2018 (ADVT. NO.28/2018)",
    link: "/",
  },
  {
    id: 55,
    content:
      "IMPORTANT ANNOUNCEMENT REGARDING MISCELLANEOUS SERVICES RECRUITMENT (FINAL) EXAMINATION, 2019",
    link: "/",
  },
  {
    id: 56,
    content:
      "SCHEDULE OF INTERVIEW FOR RECRUITMENT TO THE POST OF LECTURER IN ELCTRICAL ENGINEERING IN GOVERNMENT POLYTECHNICS IN THE WEST BENGAL GENERAL SERVICE UNDER THE DEPARTMENT OF TECHNICAL EDUCATION, TRAINING AND SKILL DEVELOPMENT, GOVERNMENT OF WEST BENGAL (ADVERTISEMENT NO.24(II)/2018)",
    link: "/",
  },
  {
    id: 57,
    content:
      "SCHEDULE OF INTERVIEW FOR RECRUITMENT TO THE POST OF LECTURER IN COMPUTER SCIENCE & TECHNOLOGY IN GOVT. POLYTECHNICS IN THE W.B.G.S. UNDER THE DEPARTMENT OF TECHNICAL EDUCATION, TRAINING AND SKILL DEVELOPMENT, GOVT. OF WEST BENGAL VIDE COMMISSION'S ADVT. NO. 24(IV)/2018",
    link: "/",
  },
  {
    id: 58,
    content:
      "NAME OF THE CANDIDATE RECOMMENDED FOR RECRUITMENT TO 01 (ONE) PERMANENT POST OF MEDICAL TECHNOLOGIST(LAB) GRADE-III IN THE DIRECTORATE OF E.S.I.(MB) SCHEME UNDER THE LABOUR DEPARTMENT, GOVT. OF W.B. AGAINST COMMISSION'S ADVERTISEMENT NO 8(3)/2018",
    link: "/",
  },
  {
    id: 59,
    content:
      "INTERVIEW SCHEDULE (PHASE - III) OF MISCELLANEOUS SERVICES RECRUITMENT EXAMINATION 2018 (ADVT. 29/2017)",
    link: "/",
  },
  {
    id: 60,
    content:
      "CUT-OFF MARKS (CATEGORY WISE) OF THE RECOMMENDED CANDIDATES FOR RECTT. TO THE POST OF MOTOR VEHICLES INSPECTOR (NON-TECHNICAL) IN THE TRANSPORT DEPARTMENT UNDER THE GOVERNMENT OF W.B. (ADVT. NO. 1/2019)",
    link: "/",
  },
  {
    id: 61,
    content:
      "THE FOLLOWING CUT-OFF MARKS (CATEGORY WISE) OF THE RECOMMENDED CANDIDATES FOR RECTT. TO THE POSTS OF LIVESTOCK DEVELOPMENT ASSISTANT IN THE DIRECTORATE OF ANIMAL RESOURCES & ANIMAL HEALTH UNDER THE ANIMAL RESOURCES DEVELOPMENT DEPARTMENT, GOVT. OF W.B. (ADVT. NO. 11/2019)",
    link: "/",
  },
  {
    id: 62,
    content:
      "Schedule of Interview for recruitment to the post of Lecturer in Mechanical Engineering in Govt. Polytechnics in the W.B.G.S. under the Department of Technical Education, Training & Skill Development,Govt. of West Bengal vide Commission's Advt. no. 24(iii)/2018",
      link: "/",
  },
  {
    id: 63,
    content: "ROLL NUMBER OF THE CANDIDATES RECOMMENDED FOR RECRUITMENT TO 74 (SEVENTY FOUR) TEMPORARY BUT LIKELY TO BE PERMANENT POSTS [8 RESERVED FOR OBC-A, 4 FOR OBC-B, 17 FOR SC, 4 FOR ST, 2 FOR MERITORIOUS SPORTSPERSON AND 1 EACH FOR PERSONS WITH DISABILITIES (BLINDNESS OR LOW VISION) & PERSONS WITH DISABILITIES (LOCO-MOTOR DISABILITY OR CEREBRAL PALSY)] OF MOTOR VEHICLES INSPECTOR (NON-TECHNICAL) IN THE TRANSPORT DEPARTMENT UNDER THE GOVERNMENT OF WEST BENGAL. [ADVT. NO- 1/2019]",
    link: "/",
  },
  {
    id: 64,
    content: "ROLL NUMBER OF THE CANDIDATES RECOMMENDED FOR RECRUITMENT TO 192 (ONE HUNDRED NINETY TWO) [UR- 94, OBC-A(NON-CREAMY LAYER)- 23, OBC-B( NONCREAMY LAYER)-13, S.C. - 41, S.T. - 13, P.D.(H.I.) - 3, P.D.(LD/CP) - 3 & M.S.P. - 2 PERMANENT POSTS OF LIVESTOCK DEVELOPMENT ASSISTANT IN THE DIRECTORATE OF ANIMAL RESOURCES AND ANIMAL HEALTH UNDER THE ANIMAL RESOURCES DEVELOPMENT DEPARTMENT GOVT. OF WEST BENGAL AGAINST ADVERTISEMENT NO. 11/2019.",
    link: "/",
  },
  {
    id: 65,
    content: "SCHEDULE OF INTERVIEW (PHASE-IV/LAST PHASE) FOR RECTT. TO THE POSTS OF FIRE OPERATOR IN THE WEST BENGAL FIRE SERVICES UNDER THE DEPARTMENT OF FIRE & EMERGENCY SERVICES, GOVT. OF WEST BENGAL, [ADVT. NO. 15/2018]",
    link: "/",
  },
  {
    id: 66,
    content: "SCHEDULE OF INTERVIEW FOR RECTT. TO THE POST OF RESEARCH INVESTIGATOR IN THE CULTURAL RESEARCH INSTITUTE UNDER BACKWARD CLASSES WELFARE DEPARTMENT, GOVT. OF W.B. IN THE W.B.G.S. (ADVT. NO. : 2/2018)",
    link: "/",
  },
  {
    id: 67,
    content: "SCHEDULE OF INTERVIEW FOR RECRUITMENT TO THE POST OF LECTURER IN SURVEY ENGINEERING IN GOVT. POLYTECHNICS IN THE W.B.G.S. UNDER THE DEPARTMENT OF TECHNICAL EDUCATION, TRAINING & SKILL DEVELOPMENT, GOVT. OF W.B. VIDE COMMISSION'S ADVT. NO. 24[VIII]/2018",
    link: "/",
  },
  {
    id: 68,
    content: "ROLL NUMBER OF THE CANDIDATES RECOMMENDED FOR RECTT. TO 118 (ONE HUNDRED EIGHTEEN) POST OF INDUSTRIAL DEVELOPMENT OFFICER IN THE DIRECTORATE OF MSME, W.B. UNDER THE MSME AND TEXTILES DEPT., GOVT. OF W.B. AGAINST ADVT. NO.10/2019.",
    link: "/",
  },
  {
    id: 69,
    content: "SCHEDULE OF INTERVIEW (PHASE-III) FOR RECTT. TO THE POSTS OF FIRE OPERATOR IN THE WEST BENGAL FIRE SERVICES UNDER THE DEPARTMENT OF FIRE AND EMERGENCY SERVICES, GOVT. OF WEST BENGAL [ADVT. NO. 15/2018]",
    link: "/",
  },
  {
    id: 70,
    content: "NAME OF THE CANDIDATES RECOMMENDED FOR RECRUITMENT TO 4(FOUR) TEMPORARY BUT LIKELY TO BE PERMANENT POST [1(ONE) EACH RESERVED FOR OBC-A & S.T. CANDIDATES] OF ASSISTANT CONTROLLER OF LEGAL METROLOGY IN THE W.B.G.S. IN THE DIRECTORATE OF LEGAL METROLOGY, UNDER THE CONSUMER AFFAIRS DEPARTMENT, GOVT. OF WEST BENGAL AGAINST COMMISSION'S ADVT. NO. 16/2019",
    link: "/",
  },
  {
    id: 71,
    content: "NAME OF THE CANDIDATES RECOMMENDED FOR APPOINTMENT TO 14 (FOURTEEN) UNRESERVED PERMANENT POSTS OF PRINCIPAL IN GOVERNMENT DEGREE COLLEGES IN W.B.S.E.S. UNDER THE HIGHER EDUCATION DEPARTMENT, GOVT. OF WEST BENGAL AGAINST COMMISSION'S ADVT. NO. 30/2019",
    link: "/",
  },
  {
    id: 72,
    content: "ANSWER KEY OF UDYAN PALAN PROJUKTI SAHAYAK RECTT. EXAM. (ADVT. 35/2019)",
    link: "/",
  },
  {
    id: 73,
    content: "Schedule of Interview for recruitment to the post of WEST BENGAL LEGAL SERVICE EXAMINATION, 2018(ADVT. NO. 28/2018)",
    link: "/",
  },
  {
    id: 74,
    content: "LIST OF CANDIDATES RECOMMENDED FOR RECTT. TO THE POST OF SUB INSPECTOR IN SUBORDINATE FOOD AND SUPPLIES SERVICE GR.III UNDER FOOD AND SUPPLIES DEPTT., GOVT. OF WEST BENGAL, AGAINST ADVT. NO. 26/2018",
    link: "/",
  },
  {
    id: 75,
    content: "LIST OF ROLL CANDIDATES CALLED FOR INTERVIEW FOR RECTT. TO THE POST OF FIRE OPERATOR (ADVT. NO. 15/2018)",
    link: "/",
  },
  {
    id: 76,
    content: "CORRIGENDUM IN CONNECTION WITH THE NOTICE NO. 321-PSC/CON(Q) REGARDING THE ANSWER KEY FOR WARD MASTER GRADE-III WAS UPLOADED IN THE COMMISSIONS WEBSITE ON 16.12.2020",
    link: "/",
  },
  {
    id: 77,
    content: "Schedule of Interview for recruitment to the post of Lecturer in Electronics & Telecommunication Engineering in Govt. Polytechnics in the W.B.G.S. under the Department of Technical Education, Training & Skill Development, Govt. of West Bengal vide Commission's advt. no. 24(vi)/2018",
    link: "/",
  },
  {
    id: 78,
    content: "LIST OF UNSUITABLE CANDIDATE OF SCIENTIFIC ASSISTANT (WELDER'S CERTIFICATION) IN THE DIRECTORATE OF BOILER UNDER THE LABOUR DEPARTMENT, GOVT. OF WEST BENGAL [ADVT. NO. 25(4)/2019]",
    link: "/",
  },
  {
    id: 79,
    content: "LIST OF UNSUITABLE CANDIDATE OF INSTRUMENT MECHANIC IN THE DIRECTORATE OF BOILER UNDER THE LABOUR DEPARTMENT, GOVT. OF WEST BENGAL [ADVT. NO. 25(5)/2019]",
    link: "/",
  },
  {
    id: 80,
    content: "SCHEDULE OF INTERVIEW (PHASE-II) FOR RECTT. TO THE POSTS OF FIRE OPERATOR IN THE WEST BENGAL FIRE SERVICES UNDER THE DEPARTMENT OF FIRE AND EMERGENCY SERVICES, GOVT. OF WEST BENGAL [ADVT. NO. 15/2018]",
    link: "/",
  },
  {
    id: 81,
    content: "IMPORTANT ANNOUNCEMENT REGARDING ADMIT CARD OF UDYAN PALAN PROJUKTI SAHAYAK EXAMINATION (ADVT. NO. - 35/2019)",
    link: "/",
  },
  {
    id: 82,
    content: "REVISED INTERVIEW SCHEDULE (PHASE - II) OF MISCELLANEOUS SERVICES RECRUITMENT EXAMINATION 2018 (ADVT. 29/2017)",
    link: "/",
  },
  {
    id: 83,
    content: "IMPORTANT ANNOUNCEMENT REGARDING CLERKSHIP (PART-II) SUPPLEMENTARY EXAMINATION , 2019 [ ADVT. NO. - 05/2019 ]",
    link: "/",
  },
  {
    id: 84,
    content: "IMPORTANT ANNOUNCEMENT REGARDING WBCS (EXE.) ETC. EXAM., 2019 (ADVT. NO. 29/2018).",
    link: "/",
  },
  {
    id: 85,
    content: "NOTICE REGARDING ANSWER KEYS OF M.V.I.(N.T.) (ADVT. NO. 32/2019), LIBRARIAN (ADVT. NO. 17(2)/2019 AND 23/2019) AND WARD MASTER GRADE-III (ADVT. NO. 24/2019)",
    link: "/",
  },
  {
    id: 86,
    content: "ANSWER KEY OF BOTANIST IN THE DIRECTORATE OF CINCHONA & OTHER MEDICINAL PLANTS, W.B. UNDER THE DEPT. OF FOOD PROCESSING INDUSTRIES & HORTICULTURE, GOVT OF W.B. ADVERTISEMENT NO. 19/2019",
    link: "/",
  },
  {
    id: 87,
    content: "ANSWER KEY OF ASSTT. DIRECTOR OF HORTICULTURE, FPI&H DEPT ADVERTISEMENT NO. 4(1)/2020.",
    link: "/",
  },
  {
    id: 88,
    content: "SCHEDULE OF INTERVIEW FOR RECTT. TO THE POSTS OF FIRE OPERATOR IN THE WEST BENGAL FIRE SERVICES UNDER THE DEPARTMENT OF FIRE & EMERGENCY SERVICES, GOVT. OF WEST BENGAL, [ADVT. NO. 15/2018]",
    link: "/",
  },
  {
    id: 89,
    content: "LIST OF ROLL CANDIDATES CALLED FOR INTERVIEW FOR RECTT. TO THE POST OF FIRE OPERATOR (ADVT. NO. 15/2018)",
    link: "/",
  },
  {
    id: 90,
    content: "IMPORTANT ANNOUNCEMENT REGARDING UDYAN PALAN PROJUKTI SAHAYAK EXAM. (ADVT. NO. -35/2019)",
    link: "/",
  },
  {
    id: 91,
    content: "DATES OF INTERVIEW FOR RECTT. TO THE POST OF FIRE OPERATOR UNDER THE DEPARTMENT OF FIRE AND EMERGENCY SERVICES, GOVT. OF W.B. [ADVT. NO. 15/2018]",
    link: "/",
  },
  {
    id: 92,
    content: "Schedule of Interview for recruitment to the post of Lecturer in Physics in Govt. Polytechnics in the W.B.G.S. under the Department of Technical Education, Training & Skill Development, Govt. of West Bengal vide Commission's Advt. no. 24(ix)/2018",
    link: "/",
  },
  {
    id: 93,
    content: "CHOICE SHEET OF SERVICE / POST IN CONNECTION WITH MISCELLANEOUS SERVICES RECRUITMENT EXAMINATION, 2018 TO BE SUBMITTED ON THE DATE OF PERSONALITY TEST",
    link: "/",
  },
  {
    id: 94,
    content: "COMMISSION'S ORDER REGARDING CLERKSHIP EXAMINATION, PART-II, 2019",
    link: "/",
  },
  {
    id: 95,
    content: "ANSWER KEY OF SCIENTIFIC ASSISTANT (CHEMICAL) IN THE DIRECTORATE OF BOILER UNDER THE LABOUR DEPT., GOVT. OF W.B. (ADVERTISEMENT NO. 25(1)/2019)",
    link: "/",
  },
  {
    id: 96,
    content: "ANSWER KEY OF DIALYSIS TECHNICIAN GRADE-III IN THE DIRECTORATE OF E.S.I. (M.B) SCHEME UNDER THE LABOUR DEPARTMENT, GOVT. OF W.B. (ADVERTISEMENT NO. 15/2019)",
    link: "/",
  },
  {
    id: 97,
    content: "ANSWER KEY OF AGRICULTURAL MARKETING OFFICER (TRAINING & CANNING) UNDER WEST BENGAL JUNIOR AGRICULTURAL SERVICE (MARKETING) UNDER THE DEPARTMENT OF AGRICULTURAL MARKETING, GOVT. OF WEST BENGAL. (ADVERTISEMENT NO. 14/2019)",
    link: "/",
  },
  {
    id: 98,
    content: "ANSWER KEY OF WELFARE OFFICER IN CORRECTIONAL HOMES OF W.B. CORRECTIONAL ADMINISTRATION. (ADVERTISEMENT NO. 31/2019)",
    link: "/",
  },
  {
    id: 99,
    content: "IMPORTANT ANNOUNCEMENT REGARDING PRELIMINARY SCREENING TESTS FOR RECRUITMENT TO THE POSTS OF ASSISTANT PROFESSOR IN LAW [ADVT. NO. 29[1(XII)]/2019] AND URDU [ADVT. NO. 29[1(XVII)]/2019] "
  },
  {
    id: 100,
    content: "SCHEDULE OF INTERVIEW FOR RECRUITMENT TO THE POST OF DISTRICT MASS EDUCATION EXTENSION OFFICER / ASSISTANT DIRECTOR, MASS EDUCATION EXTENSION / CHIEF SUPERINTENDENT, STATE WELFARE HOME, BANIPUR IN WBES UNDER THE DEPARTMENT OF MASS EDUCATION EXTENSION AND LIBRARY SERVICES, GOVT. OF WEST BENGAL (ADVERTISEMENT NO. 16(2)/2018)",
    link: "/",
  },
  {
    id: 101,
    content: "INTERVIEW SCHEDULE (PHASE - I) OF MISCELLANEOUS SERVICES RECRUITMENT EXAMINATION 2018 (ADVT. 29/2017)",
    link: "/",
  },
  {
    id: 102,
    content: "QUESTION PAPER OF CLERKSHIP EXAMINATION (PART-II),2019 HELD ON 06/12/2020 (ADVT NO.  5/2019)",
    link: "/",
  },
  {
    id: 103,
    content: "IMPORTANT ANNOUNCEMENT REGARDING SILIGURI CENTRE OF CLERKSHIP EXAMINATION (PART-II)(ADVT. NO. - 5/2019)",
    link: "/",
  },
  {
    id: 104,
    content: "IMPORTANT ANNOUNCEMENT REGARDING TO THE POST OF SUPERVISOR (FEMALE ONLY) OF I.C.D.S., 2019 (MAIN) ( ADVT. NO. - 08/2019 )",
    link: "/",
  },
  {
    id: 105,
    content: "IMPORTANT ANNOUNCEMENT (ADDRESS CHANGE) REGARDING CLERKSHIP EXAMINATION (PART -II), 2019 [ ADVT. NO. - 05/2019 ])",
    link: "/",
  },
  {
    id: 106,
    content: "SCHEDULE OF INTERVIEW FOR RECRUITMENT TO THE POST OF LECTURER IN CIVIL ENGINEERING IN GOVERNMENT POLYTECHNICS IN THE WEST BENGAL GENERAL SERVICE UNDER THE DEPARTMENT OF TECHNICAL EDUCATION, TRAINING & SKILL DEVELOPMENT, GOVERNMENT OF WEST BENGAL (ADVERTISEMENT NO.24(I)/2018)",
    link: "/",
  },
  {
    id: 107,
    content: "ANSWER KEY OF ASSISTANT TOWN PLANNER UNDER THE DEPT. OF URBAN DEVELOPMENT & MUNICIPAL AFFAIRS, GOVT. OF W.B. (ADVERTISEMENT NO. 26/2019)",
    link: "/",
  },
  {
    id: 108,
    content: "ANSWER KEY OF FITTER HELPER AND LABORATORY ATTENDANT IN THE DIRECTORATE OF BOILER UNDER THE LABOUR DEPARTMENT, GOVT. OF WEST BENGAL (ADVERTISEMENT NO. 25(6)/2019 & 25(7)/2020)",
    link: "/",
  },
  {
    id: 109,
    content: "ANSWER KEY OF ASSISTANT ENGINEER (CIVIL) RECRUITMENT EXAMINATION, 2020",
    link: "/",
  },
  {
    id: 110,
    content: "CENTRE FOR CLERKSHIP EXAMINATION (PART-II), 2019 [ADVT. NO. 5/2019]",
    link: "/",
  },
  {
    id: 111,
    content: "NAME OF THE CANDIDATES RECOMMENDED FOR APPOINTMENT TO 2(TWO) PERMANENT POSTS (1 EACH RESERVED FOR S.T AND O.B.C-A CANDIDATES) OF ASSISTANT DIRECTOR OF AGRICULTURAL MARKETING (ADMINISTRATIVE) IN THE WEST BENGAL AGRICULTURAL SERVICE (MARKETING) UNDER THE AGRICULTURAL MARKETING DEPARTMENT, GOVT. OF WEST BENGAL. AGAINST COMMISSION'S ADVERTISEMENT NUMBER 27(2)/2018.",
    link: "/",
  },
  {
    id: 112,
    content: "ANSWER KEY OF WARD MASTER GRADE - III (ADVT. NO. 24/2019) ",
    link: "/",
  },
  {
    id: 113,
    content: "Answer Keys of Common Preliminary Screening Test for recruitment to the posts of LIBRARIAN in 1) Govt. Engineering and Technological colleges in WBGS and 2) Govt. Colleges under the Deptt. of Higher Education, Govt. of West Bengal ",
    link: "/",
  },
  {
    id: 114,
    content: "Detailed Programme of Personality Test for Group – D Posts of West Bengal Civil Service (Exe.) etc. Examination, 2018.",
    link: "/",
  },
  {
    id: 115,
    content: "SCHEDULE OF PERSONALITY TEST TO THE POST OF SUB-INSPECTOR IN THE SUBORDINATE FOOD & SUPPLIES SERVICE, GRADE-III, UNDER FOOD & SUPPLIES DEPARTMENT, GOVT. OF WEST BENGAL (ADVERTISEMENT NO. 26/2018).",
    link: "/",
  },
  {
    id: 116,
    content: "ANSWER KEY OF PST FOR RECTT. TO THE POST OF MOTOR VEHICLES INSPECTOR (NON-TECHNICAL), TRANSPORT, GOVT. OF W.B. (ADVT. NO. 32/2019 )",
    link: "/",
  },
  {
    id: 117,
    content: "IMPORTANT ANNOUNCEMENT REGARDING CLERKSHIP EXAMINATION (PART -II), 2019 [ ADVT. NO. - 05/2019 ]",
    link: "/",
  },
  {
    id: 118,
    content: "VENUE LISTS OF WARD MASTER GRADE-III IN THE DIRECTORATE OF E.S.I. (M.B.) SCHEME UNDER LABOUR DEPARTMENT, GOVT. OF W.B. (ADVT. NO. 24/2019)",
    link: "/",
  },
  {
    id: 119,
    content: "VENUE LISTS OF MOTOR VEHICLES INSPECTOR (NON-TECHNICAL), RECRUITMENT EXAMINATION UNDER TRANSPORT DEPARTMENT GOVT. OF W.B. ( ADVT. NO. 32/2019 )",
    link: "/",
  },
  {
    id: 120,
    content: "LIST OF THE 85 CANDIDATES WHO ARE BEING CALLED FOR PERSONALITY TEST PROVISIONALLY SUBJECT TO VERIFICATION OF THEIR ELIGIBILITY AND SUITABILITY FOR THE EXAMINATION IN ALL RESPECTS ON THE BASIS OF THE RESULTS OF THE WRITTEN EXAMINATION OF WEST BENGAL LEGAL SERVICE EXAMINATION, 2018 (ADVT. NO. 28/2018)",
    link: "/",
  },
  {
    id: 121,
    content: "PROVISIONAL LIST WITH ROLL NUMBERS OF CANDIDATES TO BE CALLED TO INTERVIEW TO THE POST OF PHARMACIST GRADE – III / PHARMACIST–CUM–SALESMAN – GRADE – III IN THE DIRECTORATE OF E.S.I. (M.B.) SCHEME UNDER THE LABOUR DEPARTMENT, GOVT. OF W.B. [ ADVT. NO. 21/2019 ] ",
    link: "/",
  },
  {
    id: 122,
    content: "Important Announcement regarding the interview for recruitment to the posts of Lecturer in Humanities [Advt. No. 24(xii)/2018] ",
    link: "/",
  },
  {
    id: 123,
    content: "POSTPONEMENT OF ONLINE INTERVIEW FOR RECTT. TO THE POST OF ASST. PROFESSOR IN TIBETAN SCHEDULED TO BE HELD ON 04TH NOVEMBER, 2020",
    link: "/",
  },
  {
    id: 124,
    content: "ADDITIONAL SCHEDULE OF INTERVIEW FOR RECRUITMENT TO THE POST PRINCIPAL IN GOVT. DEGREE COLLEGES UNDER THE HIGHER EDUCATION DEPARTMENT, GOVT. OF W.B. VIDE COMMSSION'S ADVT. NO. 30/2019",
    link: "/",
  },
  {
    id: 125,
    content: "SCHEME & SYLLABUS IN CONNECTION WITH WEST BENGAL JUDICIAL SERVICE (FINAL) EXAMINATION, 2020",
    link: "/",
  },
  {
    id: 126,
    content: "TENTATIVE SCHEDULE OF FORTHCOMING EXAMINATIONS PHASE-II",
    link: "/",
  },
  {
    id: 127,
    content: "IMPORTANT ANNOUNCEMENT REGARDING PRELIMINARY WRITTEN TEST FOR RECRUITMENT TO THE POST OF WARD MASTER GRADE-III IN THE DIRECTORATE OF E.S.I. (M.B.) SCHEME UNDER LABOUR DEPARTMENT (ADVT. NO. 24/2019) AND MOTOR VEHICLES INSPECTOR (NON-TECHNICAL) RECRUITMENT EXAMINATION UNDER TRANSPORT DEPARTMENT, GOVT. OF W.B. (ADVT. NO. 32/2019)",
    link: "/",
  },
  {
    id: 128,
    content: "ADDITIONAL SCHEDULE FOR PHYSICAL MEASUREMENT TEST FOLLOWED BY ENDURANCE TEST FOR RECRUITMENT TO THE POST OF FIRE OPERATOR. (ADVT. NO. 15/2018)",
    link: "/",
  },
  {
    id: 129,
    content: "LIST OF CANDIDATES QUALIFIED FOR WEST BENGAL JUDICIAL SERVICE RECRUITMENT EXAMINATION - 2020 (MAIN) ON THE BASIS OF THE RESULTS OF WEST BENGAL JUDICIAL SERVICE RECRUITMENT EXAMINATION - 2020 (PRELIMINARY) AGAINST ADVT. NO. 12/2020",
    link: "/",
  },
  {
    id: 130,
    content: "SCHEDULE OF PHYSICAL MEASUREMENT TEST FOLLOWED BY ENDURANCE TEST FOR RECTT. TO THE POST OF FIRE OPERATOR UNDER THE DEPARTMENT OF FIRE & EMERGENCY SERVICES, GOVT. OF W.B. ADVT. NO. : 15/2018",
    link: "/",
  },
  {
    id: 131,
    content: "FREQUENTLY ASKED QUESTIONS RELATED TO CLERKSHIP EXAMINATION 2019 (ADVT. NO. 5/2019)",
    link: "/",
  },
  {
    id: 132,
    content: "SCHEDULE OF INTERVIEW FOR RECTT. TO THE POSTS OF INDUSTRIAL DEVELOPMENT OFFICER, 2019 UNDER THE DEPARTMENT OF MICRO, SMALL & MEDIUM ENTERPRISES AND TEXTILE, GOVT. OF W.B. [ADVT. NO.10/2019]",
    link: "/",
  },
  {
    id: 133,
    content: "LIST OF CANDIDATES SUITABLE TO BE CALLED TO INTERVIEW FOR RECRUITMENT TO THE POST OF LECTURER IN SURVEY ENGINEERING IN GOVT. POLYTECHNICS IN THE WBGS UNDER THE DEPARTMENT OF TECHNICAL EDUCATION, TRAINING & SKILL DEVELOPMENT, GOVT. OF W.B. AGAINST COMMSSION'S ADVT. NO. 24(VIII)/2018",
    link: "/",
  },
  {
    id: 134,
    content: "SCHEDULE OF INTERFVIEW FOR RECRUITMENT TO THE POST PRINCIPAL IN GOVT. DEGREE COLLEGES UNDER THE HIGHER EDUCATION DEPARTMENT, GOVT. OF W.B. VIDE COMMSSION'S ADVT. NO. 30/2019",
    link: "/",
  },
  {
    id: 135,
    content: "SCHEDULE OF INTERVIEW FOR RECRUITMENT TO THE POST OF LECTURER IN ELECTRONICS & INSTRUMENTATION ENGINEERING IN GOVT. POLYTECHNICS IN THE W.B.G.S. UNDER THE DEPARTMENT OF TECHNICAL EDUCATION, TRAINING & SKILL DEVELOPMENT, GOVT. OF WEST BENGAL VIDE COMMISSION'S ADVT. NO. 24(VII)/2018",
    link: "/",
  },
  {
    id: 136,
    content: "SCHEDULE OF ONLINE INTERVIEW (VIA GOOGLE MEET) FOR RECRUITMENT TO THE POST OF ASSISTANT PROFESSOR IN TIBETAN FOR GENERAL DEGREE COLLEGES IN THE W.B.E.S. UNDER THE DEPARTMENT OF HIGHER EDUCATION, GOVT. OF WEST BENGAL VIDE COMMISSION'S ADVT. NO. 29[1(XXVI)]/2019",
    link: "/",
  },
  {
    id: 137,
    content: "SCHEDULE OF INTERVIEW FOR RECRUITMENT TO THE POST OF LECTURER IN HUMANITIES IN GOVT. POLYTECHNICS IN THE W.B.G.S. UNDER THE DEPARTMENT OF TECHNICAL EDUCATION, TRAINING & SKILL DEVELOPMENT, GOVT. OF WEST BENGAL VIDE COMMISSION'S ADVT. NO. 24(XII)/2018",
    link: "/",
  },
  {
    id: 138,
    content: "SCHEDULE OF INTERVIEW FOR RECTT. TO THE POST OF LIVESTOCK DEVELOPMENT ASSISTANT IN THE DIRECTORATE OF ANIMAL RESOURCES & ANIMAL HEALTH UNDER THE ANIMAL RESOURCES DEVELOPMENT DEPARTMENT, GOVT. OF W.B. ADVT. NO. : 11/2019",
    link: "/",
  },
  {
    id: 139,
    content: "SCHEDULE OF INTERVIEW FOR RECTT. TO THE POST OF ASSISTANT CONTROLLER OF LEGAL METROLOGY IN THE W.B.G.S. IN THE DIRECTORATE OF LEGAL METROLOGY UNDER THE CONSUMER AFFAIRS DEPARTMENT, GOVT. OF W.B. ADVT. NO. : 16/2019",
    link: "/",
  },
  {
    id: 140,
    content: "SCHEDULE OF INTERVIEW FOR RECTT. TO THE POST OF MOTOR VEHICLES INSPECTOR (NON-TECHNICAL) IN THE TRANSPORT DEPARTMENT, GOVT. OF W.B. ADVT. NO. : 1/2019",
    link: "/",
  },
  {
    id: 141,
    content: "TENTATIVE SCHEDULE OF PERSONALITY TEST AS ON 12.10.2020",
    link: "/",
  },
  {
    id: 142,
    content: "TENTATIVE SCHEDULE OF FORTHCOMING EXAMINATIONS",
    link: "/",
  },
  {
    id: 143,
    content: "ANSWER KEY OF WEST BENGAL JUDICIAL SERVICE EXAMINATION (PREL.), 2020 (ADVT. No. 12/2020)",
    link: "/",
  },
  {
    id: 144,
    content: "Results of the Half-Yearly Departmental Examination, November, 2019 for the officers of the Government of West Bengal, (Including All India Service officers) held from Monday, the 30th December, 2019 to Friday, the 17th January, 2020",
    link: "/",
  },
  {
    id: 145,
    content: "REVISED LIST OF CANDIDATES QUALIFIED FOR MISCELLANEOUS SERVICES RECRUITMENT EXAMINATION - 2019 (MAIN) ON THE BASIS OF THE RESULTS OF MISCELLANEOUS SERVICES RECRUITMENT EXAMINATION - 2019 (PRELIMINARY) AGAINST ADVT. NO. 13/2019",
    link: "/",
  },
  {
    id: 146,
    content: "FINAL ANSWER KEYS TO THE MCQ PAPER OF THE MISCELLANEOUS SERVICES RECTT.(PRELI) EXAM-2019[ADVT. No. 13/2019]",
    link: "/",
  },
  {
    id: 147,
    content: "IMPORTANT NOTICE REGARDING MISCELLANEOUS SERVICES RECRUITMENT EXAMINATION 2019 (PRELIMINARY) [ADVT. No. 13/2019]",
    link: "/",
  },
  {
    id: 148,
    content: "LIST OF CANDIDATES QUALIFIED FOR WEST BENGAL AUDIT AND ACCOUNTS SERVICE RECRUITMENT EXAMINATION - 2019 (MAIN) ON THE BASIS OF THE RESULTS OF WEST BENGAL AUDIT AND ACCOUNTS SERVICE RECRUITMENT EXAMINATION - 2019 (PRELIMINARY) AGAINST ADVT. NO. 28/2019",
    link: "/",
  },
  {
    id: 149,
    content: "LIST OF CANDIDATES CALLED TO INTERVIEW FOR RECRUITMENT TO THE POST OF LECTURER IN ELECTRONICS & TELECOMMUNICATION ENGINEERING IN GOVT. POLYTECHNICS IN THE W.B.G.S. UNDER THE DEPARTMENT OF TECHNICAL EDUCATION, TRAINING & SKILL DEVELOPMENT, GOVT. OF WEST BENGAL VIDE COMMISSION'S ADVT. NO. 24(VI)/2018",
    link: "/",
  },
  {
    id: 150,
    content: "LIST OF CANDIDATES QUALIFIED FOR MISCELLANEOUS SERVICES RECRUITMENT EXAMINATION - 2019 (MAIN) ON THE BASIS OF THE RESULTS OF MISCELLANEOUS SERVICES RECRUITMENT EXAMINATION - 2019 (PRELIMINARY) AGAINST ADVT. NO. 13/2019",
    link: "/",
  },
  {
    id: 151,
    content: "List of candidates called to interview for recruitment to the post of Medical Technologist (X-Ray), Grade-III in the Directorate of ESI(MB) Scheme under Labour Department, Govt. of W.B. Vide Commission's Advt. No. 11/2018",
    link: "/",
  },
  {
    id: 152,
    content: "LIST OF CANDIDATES QUALIFIED FOR WBCS (EXE.) ETC EXAMINATION - 2020 (MAIN) ON THE BASIS OF THE RESULTS OF WBCS (EXE.) ETC EXAMINATION - 2020 (PRELIMINARY) AGAINST ADVT. NO. 22/2019",
    link: "/",
  },
  {
    id: 153,
    content: "SCHEME AND SYLLABUS OF P.S.T. FOR RECTT. TO THE POST OF UDYAN PALAN PROJUKTI SAHAYAK IN THE DIRECTORATE OF HORTICULTURE UNDER THE FOOD PROCESSING INDUSTRIES AND HORTICULTURE DEPARTMENT, GOVT. OF W.B. (ADVT. NO. 35/2019)",
    link: "/",
  },
  {
    id: 154,
    content: "LIST OF CANDIDATES QUALIFIED FOR INTERVIEW FOR RECRUITMENT TO THE POST OF LECTURER IN ELECTRICAL ENGINEERING IN GOVT. POLYTECHNICS IN THE W.B.G.S. UNDER THE DEPT. OF TECHNICAL EDUCATION, TRAINING & SKILL DEVELOPMENT, GOVT. OF W.B. VIDE ADVT. NO. 24(II)/2018",
    link: "/",
  },
  {
    id: 155,
    content: "LIST OF CANDIDATES QUALIFIED FOR WRITTEN EXAM. ON THE BASIS OF THE RESULTS OF THE PRELIMINARY SCREENING TEST FOR RECRUITMENT TO THE POST OF SUPERVISOR (FEMALE ONLY) OF ICDS, 2019 UNDER THE DEPTT. OF WOMEN & CHILD DEVELOPMENT & SOCIAL WELFARE, GOVT. OF WEST BENGAL (ADVT. NO. 8/2019)",
    link: "/",
  },
  {
    id: 156,
    content: "SCHEDULE OF INTERVIEW FOR RECRUITMENT TO THE POST OF ASSISTANT DIRECTOR OF AGRICULTURAL MARKETING (ADMINISTRATIVE) IN THE WEST BENGAL AGRICULTURAL SERVICE (MARKETING) UNDER THE AGRICULTURAL MARKETING DEPARTMENT, GOVT. OF WEST BENGAL VIDE COMMISSION'S ADVT. NO. 27(2)/2018",
    link: "/",
  },
  {
    id: 157,
    content: "LIST OF CANDIDATES QUALIFIED FOR PERSONALITY TEST ON THE BASIS OF THE RESULTS OF THE WRITTEN TEST IN CONNECTION WITH COMPETITIVE EXAMINATION FOR RECTT. TO THE POST OF SUB-INSPECTOR IN THE W.B. SUBORDINATE FOOD & SUPPLIES SERVICE, GR-III UNDER THE FOOD & SUPPLIES DEPT., GOVT. OF W.B., 2018 (ADVT. NO. 26/2018)",
    link: "/",
  },
  {
    id: 158,
    content: "IMPORTANT NOTICE REGARDING POSTPONEMENT OF RECRUITMENT EXAMINATIONS",
    link: "/",
  },
  {
    id: 159,
    content: "LIST OF UNSUITABLE CANDIDATES FOR RECTT. TO THE POST OF ASSISTANT PROFESSOR IN PERSIAN FOR GENERAL DEGREE COLLEGES IN THE W.B.E.S. UNDER THE HIGHER EDUCATION DEPARTMENT, GOVT. OF W.B. (ADVT. NO. 29[1(XVI)]/2019)",
    link: "/",
  },
  {
    id: 160,
    content: "LIST OF UNSUITABLE CANDIDATES FOR RECTT. TO THE POST OF LECTURER IN ELECTRONICS & TELECOMMUNICATION ENGINEERING IN GOVT. POLYTECHNICS IN THE W.B.G.S. UNDER THE DEPARTMENT OF TECHNICAL EDUCATION, TRAINING & SKILL DEVELOPMENT, GOVT. OF W.B. (ADVT. NO. 24(VI)/2018)",
    link: "/",
  },
  {
    id: 161,
    content: "NAME OF THE CANDIDATE RECOMMENDED FOR APPOINTMENT AGAINST 1 (ONE) ANTICIPATED VACANCY IN THE WEST BENGAL JUDICIAL SERVICE ON THE BASIS OF THE RESULTS OF THE W.B.J.S. EXAMINATION, 2019",
    link: "/",
  },
  {
    id: 162,
    content: "IMPORTANT ANNOUNCEMENT ON EXTENSION OF LAST DATE FOR FILLING APPLICATION FOR RECTT. TO THE POST OF ASSISTANT ENGINEER(CIVIL) ADVT NO 13/2020 ",
    link: "/",
  },
  {
    id: 163,
    content: "IMPORTANT NOTICE REGARDING POSTPONEMENT OF RECRUITMENT EXAMINATIONS",
    link: "/",
  },
  {
    id: 164,
    content: "LIST OF CANDIDATES QUALIFIED FOR INTERVIEW FOR RECTT TO THE POST OF TUTOR, NURSING TRAINING INSTITUTE (FOR GNM COURSE) IN THE DIRECTORATE OF ESI (MB) SCHEME, W.B. UNDER THE LABOUR DEPT., GOVT. OF W.B. (ADVT. NO 34/2019) ",
    link: "/",
  },
  {
    id: 165,
    content: "LIST OF CANDIDATES RECOMMENDED FOR RECTT TO THE POSTS OF ASST. MASTER/MISTRESS IN PHYSICS/CHEMISTRY IN ENGLISH MEDIUM GOVT. HIGH MADRASAH UNDER THE MA & ME DEPT., GOVT. OF W.B. (ADVT. NO 4(5)/2017) ",
    link: "/",
  },
  {
    id: 166,
    content: "SCHEME AND SYLLABUS FOR PRELIMINARY SCREENING TEST FOR RECRUITMENT TO THE POST OF ASSISTANT DIRECTOR OF HORTICULTURE [ADVT. NO. 4(1)/2020] ",
    link: "/",
  },
  {
    id: 167,
    content: "LIST OF UNSUITABLE CANDIDATES FOR RECTT. TO THE POST OF LECTURER IN ELECTRICAL ENGINEERING IN GOVT. POLYTECHNICS IN THE W.B.G.S. UNDER THE DEPT. OF TECHNICAL EDUCATION, TRAINING & SKILL DEVELOPMENT, GOVT. OF W.B. (ADVT. NO 24(II)/2018)",
    link: "/",
  },
  {
    id: 168,
    content: "LIST OF CANDIDATES SHORTLISTED FOR INTERVIEW FOR RECRUITMENT TO THE POST OF LECTURER IN COMPUTER SCIENCE & TECHNOLOGY IN GOVT. POLYTECHNICS IN THE W.B.G.S. UNDER THE DEPT. OF TECHNICAL EDUCATION, TRAINING & SKILL DEVELOPMENT, GOVT. OF W.B. VIDE COMMISSION'S ADVT. NO. 24(IV)/2018",
    link: "/",
  },
  {
    id: 169,
    content: "LIST OF CANDIDATES QUALIFIED FOR CLERKSHIP (PART-II) EXAMINATION, 2019 ON THE BASIS OF THE RESULTS OF THE PART-I OF THE SAID EXAMINATION CONDUCTED ON 25/01/2020 (ADVT. NO. 05/2019) ARRANGED IN ASCENDING ORDER OF ROLL NUMBERS.",
    link: "/",
  },
  {
    id: 170,
    content: "List of candidates recommended for rectt. to the posts of Asst. Master/Mistress in Geography in English Medium Govt. High Madrasah under the Minority Affairs & Madrasah Education dept., Govt. of W.B. (Advt. 4(6)/2017)",
    link: "/",
  },
  {
    id: 171,
    content: "LIST OF CANDIDATES QUALIFIED FOR INTERVIEW FOR RECRUITMENT TO THE POST OF LECTURER IN MECHANICAL ENGINEERING IN GOVT. POLYTECHNICS IN THE W.B.G.S. UNDER THE DEPT. OF TECHNICAL EDUCATION, TRAINING & SKILL DEVELOPMENT, GOVT. OF WEST BENGAL VIDE COMMISSION'S ADVT. NO. 24(III)/2018",
    link: "/",
  },
  {
    id: 172,
    content: "List of candidates qualified for interview on the basis of P.S.T. in connection with rectt. to the post of Lecturer in Civil Engineering in Govt. Polytechnics in the W.B.G.S. under the Dept. of Technical Education, Training & Skill Development, Govt. of W.B. vide Commission's Advt. No. 24(i)/2018.",
    link: "/",
  },
  {
    id: 173,
    content: "IMPORTANT ANNOUNCEMENT REGARDING PERSONALITY TEST IN RESPECT OF W.B.C.S. (EXE.) ETC. EXAMINATION, 2018 FOR GROUP 'C' SERVICE & POSTS",
    link: "/",
  },
  {
    id: 174,
    content: "SCHEME AND SYLLABUS OF PRELIMINARY SCREENING TEST FOR RECTT. TO THE POST OF GEO-PHYSICAL ASSISTANT IN THE DIRECTORATE OF STATE WATER INVESTIGATION UNDER THE DEPT. OF WATER RESOURCES INVESTIGATION AND DEVELOPMENT, GOVT. OF W.B. (ADVT. NO. 38/2019)",
    link: "/",
  },
  {
    id: 175,
    content: "STATUS OF SOME IMPORTANT EXAMINATIONS (AS ON 2ND JULY,2020)",
    link: "/",
  },
  {
    id: 176,
    content: "NAME OF THE CANDIDATE RECOMMENDED FOR APPOINTMENT TO THE POST OF ASST. PROFESSOR IN SANTALI FOR GENERAL DEGREE COLLEGES IN THE WBES UNDER THE HIGHER EDUCATION DEPT., GOVT. OF WEST BENGAL.[ADVT. NO. 29[1(XXIII)/2019]",
    link: "/",
  },
  {
    id: 177,
    content: "LIST OF CANDIDATES RECOMMENDED FOR APPOINTMENT IN THE WEST BENGAL FOREST SERVICE AND WEST BENGAL SUB-ORDINATE FOREST SERVICE ON THE BASIS OF THE RESULTS OF THE WEST BENGAL FOREST SERVICE & WEST BENGAL SUB-ORDINATE FOREST SERVICE EXAMINATION, 2018. [ ADVT. NO. NO.14/2018 ]",
    link: "/",
  },
  {
    id: 178,
    content: "List of Candidates to be called to Interview for rectt. to the post of District Mass Education Extension Officer / Assistant Director Mass Education Extension / Chief Superintendent, State Welfare Home, Banipur against Commission’s Advt. No. 16(2)/2018",
    link: "/",
  },
  {
    id: 179,
    content: "NOTIFICATION FOR FUNCTIONING OF OFFICE OF THE PSC WEST BENGAL WITH EFFECT FROM 08/06/2020",
    link: "/",
  },
  {
    id: 180,
    content: "LIST OF CANDIDATES RECOMMENDED FOR APPOINTMENT TO THE POSTS OF ASST. ARCHITECT IN THE W.B.G.S. UNDER P.W.D., GOVT. OF W.B. VIDE ADVT. NO. 16(3)/2018",
    link: "/",
  },
  {
    id: 181,
    content: "LIST OF THE 3920 CANDIDATES CALLED FOR THE PERSONALITY TEST PROVISIONALLY ON THE BASIS OF THE RESULTS OF WRITTEN EXAMINATION OF MISCELLANEOUS SERVICES RECRUITMENT EXAMINATION, 2018",
    link: "/",
  },
  {
    id: 182,
    content: "LIST OF ROLL NUMBERS OF THE CANDIDATES QUALIFIED FOR INTERVIEW ON THE BASIS OF PRELIMINARY SCREENING TEST IN CONNECTION WITH RECRUITMENT TO THE POST OF INDUSTRIAL DEVELOPMENT OFFICER IN THE DTE. OF MSME, W.B. UNDER THE MSME AND TEXTILES DEPT., GOVT. OF W.B. (ADVT. NO. 10/2019)",
    link: "/",
  },
  {
    id: 183,
    content: "LIST OF ROLL NUMBERS OF THE CANDIDATES QUALIFIED FOR INTERVIEW ON THE BASIS OF PST IN CONNECTION WITH RECTT. TO THE POST OF ASST. CONTROLLER OF LEGAL METROLOGY IN THE W.B.G.S. IN THE DTE. OF LEGAL METROLOGY UNDER THE CONSUMER AFFAIRS DEPT., GOVT. OF W.B. (ADVERTISEMENT NO. 16/2019)",
    link: "/",
  },
  {
    id: 184,
    content: "NAME OF THE CANDIDATES RECOMMENDED FOR ARECTT. TO THE POSTS OF LECTURER IN CHEMISTRY IN GOVT. POLYTECHNICS, GOVT. OF W.B. VIDE ADVT. NO. 24(X)/2018",
    link: "/",
  },
  {
    id: 185,
    content: "LIST OF CANDIDATES QUALIFIED FOR INTERVIEW ON THE BASIS OF P.S.T. IN CONN. WITH RECTT. TO THE POST OF LECTURER IN PHYSICS IN GOVT. POLYTECHNICS, GOVT. OF W.B. VIDE ADVT. NO. 24(IX)/2018",
    link: "/",
  },
  {
    id: 186,
    content: "LIST OF CANDIDATES RECOMMENDED FOR RECTT. TO THE POSTS OF JT. DIRECTOR OF TEXTILES (HANDLOOM SPINNING MILLS SILK WEAVING HANDLOOM BASED HANDICRAFTS DIVISION) UNDER THE MSME AND TEXTILES DEPT. VIDE ADVT. NO. 16(1)/2018",
    link: "/",
  },
  {
    id: 187,
    content: "LIST OF CANDIDATES RECOMMENDED FOR RECTT. TO THE POSTS OF LECTURER IN CHEMICAL ENGINEERING IN GOVT. POLYTECHNICS UNDER THE DEPT. OF TECHNICAL EDUCATION, TRAINING AND SKILL DEVELOPMENT VIDE ADVT. NO. 24(XV)/2018",
    link: "/",
  },
  {
    id: 188,
    content: "NAME OF THE CANDIDATES RECOMMENDED FOR APPOINTMENT TO THE POST OF LECTURER IN MATHEMATICS IN GOVT. POLYTECHNICS AS PER ADVT. NO. 24(XI)/2018",
    link: "/",
  },
  {
    id: 189,
    content: "IMPORTANT ANNOUNCEMENT REGARDING POSTPONEMENT OF ALL WRITTEN EXAMINATIONS, INTERVIEWS AND PERSONALITY TESTS UNTIL FURTHER NOTIFICATION BY PUBLIC SERVICE COMMISSION, WEST BENGAL",
    link: "/",
  },
  {
    id: 190,
    content: "IMPORTANT ANNOUNCEMENT REGARDING POSTPONEMENT OF ALL THE INTERVIEWS AND PERSONALITY TEST UPTO 31ST MARCH BY PUBLIC SERVICE COMMISSION, WEST BENGAL",
    link: "/",
  },
  {
    id: 191,
    content: "IMPORTANT ANNOUNCEMENT REGARDING POSTPONEMENT OF WRITTEN EXAMINATIONS AND PHYSICAL ENDURANCE TEST CONDUCTED BY PUBLIC SERVICE COMMISSION, WEST BENGAL",
    link: "/",
  },
  {
    id: 192,
    content: "SCHEDULE OF INTERVIEW FOR RECRUITMENT TO THE POST OF ASSISTANT PROFESSOR IN TIBETAN FOR GENERAL DEGREE COLLEGES IN THE W.B.E.S. UNDER THE DEPARTMENT OF HIGHER EDUCATION, ADVT. NO. 29[1(XXVI)]/2019",
    link: "/",
  },
  {
    id: 193,
    content: "RECOMMENDATION FOR APPOINTMENT TO THE POST OF PRESS AND FORMS MANAGER, WEST BENGAL, IN W.B. GENERAL SERVICE UNDER THE DEPARTMENT OF CORRECTIONAL ADMINISTRATION, ADVT. NO. 12/2019",
    link: "/",
  },
  {
    id: 194,
    content: "RECOMMENDATION FOR APPOINTMENT TO THE POST OF ELECTRICAL INSPECTOR, IN THE WEST BENGAL ELECTRICITY SERVICE UNDER THE DEPARTMENT OF POWER AND NON-CONVENTIONAL ENERGY SOURCES, ADVT. NO. 30(1)/2018",
    link: "/",
  },
  {
    id: 195,
    content: "RECOMMENDATION FOR APPOINTMENT TO THE POST OF DIRECTOR, CULTURAL RESEARCH INSTITUTE UNDER THE DEPTT. OF B.C.W, ADVT. NO. 20(1)/2019",
    link: "/",
  },
  {
    id: 196,
    content: "ANSWER KEY OF WRITTEN EXAMINATION FOR RECTT. TO THE POST OF REGISTRAR IN GOVT. ENGG COLLEGES AND TECHNOLOGICAL COLLEGES (ADVT. NO. 17/2019)",
    link: "/",
  },
  {
    id: 197,
    content: "SCHEME AND SYLLABUS OF THE PRELIMINARY SCREENING TEST FOR RECTT. TO THE POST OF ASSISTANT TOWN PLANNER UNDER THE DEPT. OF URBAN DEVELOPMENT & MUNICIPAL AFFAIRS, GOVT. OF W.B. VIDE ADVT. NO. 26/2019.",
    link: "/",
  },
  {
    id: 198,
    content: "SCHEDULE OF INTERVIEW FOR RECRUITMENT TO THE POST OF LECTURER IN HUMANITIES IN GOVT. POLYTECHNICS IN THE W.B.G.S. UNDER THE DEPARTMENT OF TECHNICAL EDUCATION, TRAINING & SKILL DEVELOPMENT, GOVT. OF WEST BENGAL VIDE COMMISSION'S ADVT. NO. 24(XII)/2018",
    link: "/",
  },
  {
    id: 199,
    content: "ANSWER KEY OF WRITTEN EXAMINATION FOR RECTT. TO THE POST OF PHARMACIST GRADE-III / PHARMACIST-CUM-SALESMAN-GRADE-III, DEPARTMENT OF LABOUR, GOVT OF W.B. (ADVT. NO. 21/2019)",
    link: "/",
  },
  {
    id: 200,
    content: "SCHEDULE OF INTERVIEW FOR RECTT. TO THE POST OF LIVESTOCK DEVELOPMENT ASSISTANT IN THE DIRECTORATE OF ANIMAL RESOURCES & ANIMAL HEALTH UNDER THE ANIMAL RESOURCES DEVELOPMENT DEPARTMENT, GOVT. OF W.B. ADVT. NO. 11/2019",
    link: "/",
  },
  {
    id: 201,
    content: "SCHEDULE OF INTERVIEW FOR RECRUITMENT TO THE POST OF LECTURER IN ELECTRONICS & INSTRUMENTATION ENGINEERING IN GOVT. POLYTECHNICS IN THE W.B.G.S. UNDER THE DEPARTMENT OF TECHNICAL EDUCATION, TRAINING & SKILL DEVELOPMENT, GOVT. OF WEST BENGAL VIDE COMMISSION'S ADVT. NO. 24(VII)/2018",
    link: "/",
  },
  

];
