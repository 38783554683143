import React from "react";
// import Styles from "../servicePagesParts.module.scss";
import { Flex } from "antd";
import Button from "../../../Common/Button/Button";
import { Dummynews, portals } from "../../../Constants/ApplicationsData";
import ButtonWithNews from "../../../Common/ButtonWithNews/ButtonWithNews";

export default function ApplicationsPart({ newsRecords }) {
  return (
    <Flex vertical={true} gap={20} style={{ width: "40%" }}>
      {portals.map((portal) => {
        const handleClick = () => {
          if (portal.path === "#") return;
          window.open(portal.path, "_blank");
        };
        return (
          <Button
            key={portal.id}
            btnText={portal.label}
            onClick={handleClick}
          />
        );
      })}
      <ButtonWithNews
        onbtnClick={() => {}}
        btnText="Admit Card"
        news={Dummynews}
        newsRecords={newsRecords}
      />
      <ButtonWithNews
        onbtnClick={() => {}}
        btnText="Call Letter"
        newsRecords={newsRecords}
      />
      <Button btnText="Candidate LOGIN »" />
    </Flex>
  );
}
