import React from "react";
import styles from "./buttonWithNews.module.scss";
import { Flex } from "antd";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

function NewsDiv({ text, newsRecords }) {
  const onAdmitClick = () => {
    newsRecords.current = { name: text };
  };
  return (
    <Flex vertical={true} gap={12} className={styles.news_div}>
      <Flex vertical={false} gap={8}>
        {text !== "" && (
          <img
            src="/images/examArrow.png"
            alt="new_gif"
            width={11}
            height={14}
          />
        )}
        <span className={styles.newsdiv_text}>
          {text !== "" && (
            <img src="/new11.gif" alt="new_gif" width={30} height={14} />
          )}
          {text}
        </span>

        {/* <span className={styles.newsdiv_text}>{text}</span> */}
      </Flex>
      {text !== "" && (
        <Link
          to="/admit-card"
          style={{ textDecoration: "none" }}
          className={styles.click_text}
          onClick={onAdmitClick}
        >
          Click here!
        </Link>
      )}
    </Flex>
  );
}

export default function ButtonWithNews({
  onbtnClick,
  btnText,
  news = [],
  newsRecords,
}) {
  return (
    <Flex vertical={true}>
      <Button onClick={onbtnClick} btnText={btnText} />
      {news.length === 0 ? (
        <NewsDiv text={""} newsRecords={newsRecords} />
      ) : (
        news.map((item, index) => {
          return (
            <NewsDiv
              key={index}
              text={item.content}
              newsRecords={newsRecords}
            />
          );
        })
      )}
    </Flex>
  );
}
