import { Flex } from "antd";
import React from "react";
import Styles from "../ShowServisces/servicepage.module.scss";
import HeaderPart from "../ShowServisces/ServicePagesParts/HeaderPart";
import Navbar from "../ShowServisces/ServicePagesParts/Navbar";
import Footer from "../ShowServisces/ServicePagesParts/Footer";
import BodyPart from "./ResultPageParts/Bodypart";
export default function ResultsPage({ newsRecords }) {
  return (
    <Flex vertical={false} justify="center" className={Styles.container}>
      <Flex vertical={true} className={Styles.main_container} align="center">
        <HeaderPart />
        <Navbar />
        <BodyPart newsRecords={newsRecords} />
        <Footer />
        <div className={Styles.bottom_div}></div>
      </Flex>
    </Flex>
  );
}
