import React from "react";
import HeaderStyles from "./servicePagesParts.module.scss";
import { Flex } from "antd";
export default function HeaderPart() {
  return (
    <Flex
      vertical={false}
      justify="space-between"
      className={HeaderStyles.headerPart}
      gap={20}
    >
      <Flex
        vertical={false}
        gap={20}
        align="center"
        className={HeaderStyles.first_header_child}
      >
        <img src="/nationalEmblem.png" alt="logo" width={53} height={89} />
        <Flex vertical={true} gap={12}>
          <span className={HeaderStyles.main_header_text}>
            Public Service Commission
          </span>
          <span className={HeaderStyles.sub_header_text}>West Bengal</span>
        </Flex>
      </Flex>
      <Flex
        vertical={true}
        justify="center"
        className={HeaderStyles.second_header_child}
      >
        <span className={HeaderStyles.helpdesk_text}>
          <b className={HeaderStyles.boldText}>
            Technical helpdesk (10AM to 6PM):
          </b>{" "}
          03340585640
        </span>
        <span className={HeaderStyles.helpdesk_text}>
          <b className={HeaderStyles.boldText}>Email Helpdesk</b>:
          pscwbhelp@gmail.com
        </span>
      </Flex>
    </Flex>
  );
}
