import React, { useState } from "react";
import BodyStyles from "./servicePagesParts.module.scss";
import { Flex } from "antd";
import ApplicationsPart from "./SubBodyParts/ApplicationsPart";
import AnnouncementPart from "./SubBodyParts/AnnouncementPart";
import { Announcements } from "../../Constants/AnnouncementData";
import MarqueeText from "./AnimatedPart/AnimatedPart";
export default function BodyPart({ newsRecords }) {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <Flex vertical={true} gap={12} className={BodyStyles.body_part}>
      <div style={{ width: "100%" }}>
        <MarqueeText isClicked={isClicked} setIsClicked={setIsClicked} />
      </div>
      <Flex vertical={false} gap={18} align="flex-start">
        <ApplicationsPart newsRecords={newsRecords} />
        <AnnouncementPart data={Announcements} />
      </Flex>
    </Flex>
  );
}
