import { axiosInstance } from "../axiosInstance";
import { Endpoints } from "../EndPoints";


export const tocheckAuth = async (data) => {
    try {
        const response = await axiosInstance.post('/check-auth', data);
        return response;
    } catch (error) {
        console.error(error.message);
        alert('Invalid credentials');
    }
}

export const togetAllStudentData = async () => {
    try {
        const response = await axiosInstance.get(Endpoints._actionOnData);
        return response;
    } catch (error) {
        console.error(error.message);
    }
}

export const toGetSingleStudentData = async (roll) => {
    try {
        const response = await axiosInstance.get(`${Endpoints._actionOnData}/${roll}`);
        return response;
    } catch (error) {
        console.error(error.message);
        alert("No data found");
    }
}

export const toaddNewStudentData = async (data) => {
    try {
        const response = await axiosInstance.post(Endpoints._actionOnData, data);
        return response;
    } catch (error) {
        console.error(error.message);
        alert('This data can not be saved');
    }
}

export const todeleteStudentData = async (roll) => {
    try {
        const response = await axiosInstance.delete(`${Endpoints._actionOnData}/${roll}`);
        return response;
    } catch (error) {
        console.error('This data can not be deleted');
        alert('Data is not present');
    }
}