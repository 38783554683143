import { useRef } from "react";
import "./App.css";
import ResultsPage from "./Components/Results/Results";
import ShowServiceComponent from "./Components/ShowServisces/ShowServiceComponent";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdmitCardPage from "./Components/AdmitCardPage/AdmitCardPage";
import Adminpage from "./Components/Admin/AdminPage";
import AdminPass from "./Components/Admin/AdminPass";
function App() {
  const newsRecords = useRef(null);
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={<ShowServiceComponent newsRecords={newsRecords} />}
          />
          <Route
            path="/result"
            element={<ResultsPage newsRecords={newsRecords} />}
          />
          <Route
            path="/admit-card"
            element={<AdmitCardPage newsRecords={newsRecords} />}
          />
          <Route path="/Admin-pass" element={<AdminPass/>} />
          <Route path="/admin" element={<Adminpage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
