import React, { useState } from "react";
import Styles from "./MarqueeText.module.scss"; // Import the SCSS file
import { Flex } from "antd";

export default function MarqueeText({ isClicked, setIsClicked }) {
  const [isPaused, setIsPaused] = useState(false);

  const handleClicked = () => {
    setIsClicked(true);
  };

  return (
    <div className={Styles.marquee_container}>
      <div
        className={`${Styles.marquee_text} ${isPaused ? Styles.paused : ""}`}
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
        onClick={handleClicked}
        style={{
          color: isClicked ? "#551a8b" : "blue",
        }}
      >
        <Flex
          vertical={false}
          align="center"
          gap={4}
          onClick={() => window.open("/", "_blank")}
        >
          <img src="/images/new.gif" alt="new-gif" width={30} height={14} />
          <span style={{ textDecoration: "underline" }}>
            CANDIDATES OF SUB-DIVISIONAL INFORMATION AND CULTURAL OFFICER(ADVT.
            NO. 25/2018),PHARMACIST GRADE – III / PHARMACIST–CUM–SALESMAN –
            GRADE – III (ADVT. NO. 21/2019),AUDIT AND ACCOUNTS SERVICE(ADVT. NO.
            28/2019),MISCELLANEOUS SERVICES(ADVT. NO. 13/2019),Medical
            Technologist (X-Ray)(Advt. No. 11/2018), SUPERVISOR(FEMALE ONLY) OF
            ICDS (ADVT NO:8/2019),CLERKSHIP (ADVT NO:5/2019), SI OF FOOD (ADVT
            NO: 26/2018) AND LECTURER OF GOVT. POLYTECHNICS RECTT. EXAMINATIONS
            (ADVT NO: 24/2018) MAY SEE THEIR ROLL NUMBERS IN THE APPLICATION
            FORMS BY LOGGING INTO THEIR DASHBOARD (WWW.PSCWBAPPLICATION.IN)
          </span>
        </Flex>
      </div>
    </div>
  );
}
