import React from "react";
import NavStyles from "./servicePagesParts.module.scss";
import { Flex } from "antd";
export default function Footer() {
  return (
    <Flex vertical={true} className={NavStyles.navbar_container}>
      <Flex
        vertical={false}
        justify="space-between"
        className={NavStyles.navbar_container__curvedPart}
      >
        <img
          src="/images/nvdotLeftBottom.png"
          alt="left-dot"
          width={9}
          height={11}
        />
        <img
          src="/images/nvdotRightBottom.png"
          alt="left-dot"
          width={9}
          height={11}
        />
      </Flex>
      <Flex
        vertical={false}
        justify="space-between"
        className={NavStyles.navbar_container__Navbar}
      >
        <span
          className={NavStyles.navbar_container__Navbar__links}
          style={{ paddingLeft: "30px" }}
        >
          Copyright © 2018, Public Service Commission, WB.
        </span>
      </Flex>
    </Flex>
  );
}
