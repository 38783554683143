import React from "react";
import Styles from "./MovingText.module.scss"; // Import the SCSS file
import { Flex } from "antd";

export default function MovingText({ isClicked, setIsClicked }) {
  return (
    <div className={Styles.marquee_container}>
      <div className={`${Styles.marquee_text}`}>
        <Flex vertical={false} align="center" gap={4}>
          <span>Welcome to West Bengal Public Service Commission</span>
        </Flex>
      </div>
    </div>
  );
}
