import React, { useState } from "react";
import BodyStyles from "../ResultPageParts.module.scss";
import { Flex, Form } from "antd";
import { toGetSingleStudentData } from "../../../../Helpers/query/queryFunctions";

const ResultTable = () => {
  const [studentDetailsForm] = Form.useForm();
  const [studentData, setStudentData] = useState(null);
  const onSubmit = async () => {
    const formdata = await studentDetailsForm.validateFields();
    if (!formdata.roll || !formdata.name) {
      return alert("Please fill the form");
    }
    const response = await toGetSingleStudentData(formdata.roll);
    setStudentData(response.data);

  }
  return (
    <Flex vertical={true} className={BodyStyles.result_container}>
      <span className={BodyStyles.header_text}>Result</span>
      <Flex vertical={true} gap={14} className={BodyStyles.table_container}>
        <Form form={studentDetailsForm}>
          <table className={BodyStyles.result_table}>
            <tbody>
              <tr>
                <td>
                  <span>Candidate's Roll</span>
                </td>
                <td>
                  <Form.Item name="roll" style={{ margin: "0px" }} rules={[{ required: true, message: "Please enter the roll number" }]}>
                    <input type="text" />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td>
                  <span>Candidate's Name</span>
                </td>
                <td>
                  <Form.Item name="name" style={{ margin: "0px" }} rules={[{ required: true, message: "Please enter the name" }]}>
                    <input type="text" />
                  </Form.Item>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
        <Flex vertical={false} justify="right" style={{ paddingRight: "50px", boxSizing: "border-box", paddingBottom: `${!studentData ? '14px' : '0px'}` }}>
          <button className={BodyStyles.submit_button} onClick={onSubmit}>Submit</button>
        </Flex>
        {
          studentData && (
            <div className={BodyStyles.showTable_wrapper}>
            <table className={BodyStyles.show_table}>
              <tbody>
                <tr>
                  <th>Candidate's Roll</th>
                  <th>Candidate's Name</th>
                  <th>Father/Husband Name</th>
                  <th>Post</th>
                  <th>DOB</th>
                  <th>Remarks</th>
                </tr>
                <tr>
                  <td>{studentData?.roll}</td>
                  <td>{studentData?.name}</td>
                  <td>{studentData?.fatherName}</td>
                  <td>{studentData?.post}</td>
                  <td>{studentData?.dob}</td>
                  <td>{studentData?.remarks}</td>
                </tr>
              </tbody>
            </table>
            </div>
          )
        }
      </Flex>
      <span className={BodyStyles.bottom_text} style={{ marginTop: '10px' }}>DISCLAIMER: THIS RESULT IS PREPARED AND BEING DISPLAYED ELECTRICALLY</span>
    </Flex>
  );
};

export default ResultTable;
