import React from "react";
import styles from "./Button.module.scss";
export default function Button({ onClick, btnText }) {
  return (
    <div className={styles.custom_button} onClick={onClick}>
      <img
        src="/images/announceArrow.png"
        alt="arrow-png"
        width={18}
        height={23}
      />
      <span className={styles.button_text}>{btnText}</span>
    </div>
  );
}
