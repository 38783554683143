export const handlegotoHomePage = () => {
  window.open("/", "_blank");
};

export const handlePdfOpen = (path) => {
  window.open(path, "_blank");
};

export const getDateArrayForSelectComponent = () => {
  let arr = [];
  for (let i = 0; i < 31; i++) {
    let obj = {
      id: i,
      label: (i + 1).toString(),
      value: (i + 1).toString(),
    };
    arr.push(obj);
  }
  return arr.map((item) => {
    return {
      ...item,
      label: item.label.length === 1 ? `0${item.label}` : item.label,
    };
  });
};

export const getYearArrayForSelectComponent = () => {
  let firstYear = "2010";
  let arr = [
    {
      id: 0,
      label: firstYear,
      value: firstYear,
    },
  ];
  for (let i = 1; i < 60; i++) {
    let obj = {
      id: i,
      label: (firstYear - i).toString(),
      value: (firstYear - i).toString(),
    };
    arr.push(obj);
  }
  return arr;
};

export const getMonthArrayForSelectComponent = () => {
  return [
    { id: 1, label: "January", value: "January" },
    { id: 2, label: "February", value: "February" },
    { id: 3, label: "March", value: "March" },
    { id: 4, label: "April", value: "April" },
    { id: 5, label: "May", value: "May" },
    { id: 6, label: "June", value: "June" },
    { id: 7, label: "July", value: "July" },
    { id: 8, label: "August", value: "August" },
    { id: 9, label: "September", value: "September" },
    { id: 10, label: "October", value: "October" },
    { id: 11, label: "November", value: "November" },
    { id: 12, label: "December", value: "December" },
  ];
};
