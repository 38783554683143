import { Flex, Form, Input, message } from "antd";
import React from "react";
import Styles from "./Admin.module.scss";
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { tocheckAuth } from "../../Helpers/query/queryFunctions";

export default function AdminPass() {
  const [AdminEntryFrom] = Form.useForm();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = await AdminEntryFrom.validateFields();
    try {
      const res = await tocheckAuth(formData);
      res.data.status === true ? navigate("/admin") : messageApi.open({
        type: "error",
        content: "Invalid credentials",
        duration: 3,
      });
    }
    catch (err) {
      console.error(err.message);
    }
  }

  return (
    <>
      {contextHolder}
      <Flex
        vertical={true}
        gap={40}
        align="center"
        justify="center"
        className={Styles.admin_container}
      >
        <h1>Admin portal</h1>
        <Form form={AdminEntryFrom} className={Styles.card_styles}> {/* Use form prop */}
          <Flex vertical={true} gap={20}>
            <span>
              Please enter your name and authenticated password to access the
              admin portal. This page is a sensitive area of the portal.
            </span>
            <Flex vertical={true}>
              <span>Admin name*</span>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Please enter admin name" }]}
                style={{ margin: "0px" }}
              >
                <Input placeholder="Admin name" width={"100%"} />
              </Form.Item>
            </Flex>
            <Flex vertical={true}>
              <span>Password*</span>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Please enter password" }]}
                style={{ margin: "0px" }}
              >
                <Input.Password
                  placeholder="input password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
            </Flex>
            <button className={Styles.submit_button} onClick={handleSubmit}>
              Submit
            </button>
          </Flex>
        </Form>
      </Flex>
    </>
  );
}