import { Flex } from "antd";
import React from "react";
import Styles from "./servicepage.module.scss";
import HeaderPart from "./ServicePagesParts/HeaderPart";
import BodyPart from "./ServicePagesParts/BodyPart";
import Navbar from "./ServicePagesParts/Navbar";
import Footer from "./ServicePagesParts/Footer";
export default function ShowServiceComponent({ newsRecords }) {
  return (
    <Flex
      vertical={false}
      justify="center"
      align="center"
      className={Styles.container}
    >
      <Flex vertical={true} className={Styles.main_container} align="center">
        <HeaderPart />
        <Navbar />
        <BodyPart newsRecords={newsRecords} />
        <Footer />
        <div className={Styles.bottom_div}></div>
      </Flex>
    </Flex>
  );
}
