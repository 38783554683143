import React from "react";
import Styles from "./AdmitCardPages.module.scss";
import headerStyles from "../ShowServisces/ServicePagesParts/servicePagesParts.module.scss";
import { Flex } from "antd";
import MovingText from "./MovingText/MovingText";
import AdmitForm from "./AdmitForm/AdmitForm";
export default function AdmitCardPage({ newsRecords }) {
  return (
    <Flex vertical={true} className={Styles.main_container}>
      <div className={Styles.top_div}></div>
      <Flex
        vertical={false}
        justify="space-between"
        align="center"
        className={Styles.header_div}
      >
        <Flex vertical={false} align="center" gap={12}>
          <img src="/nationalEmblem.png" alt="logo" width={53} height={89} />
          <Flex vertical={true} gap={12}>
            <span className={headerStyles.main_header_text}>
              Public Service Commission
            </span>
            <span className={headerStyles.sub_header_text}>West Bengal</span>
          </Flex>
        </Flex>
        <Flex vertical={true} align="right" gap={8} className={Styles.helpdesk}>
          <span className={Styles.helpdesk__text}>
            <strong>Technical Helpdesk (10AM to 6PM): </strong>
            03340585640
          </span>
          <span className={Styles.helpdesk__text}>
            <strong>Email Helpdesk: </strong>
            pscwbhelp@gmail.com
          </span>
        </Flex>
      </Flex>
      <Flex vertical={false} justify="center">
        <Flex
          vertical={false}
          align="center"
          className={Styles.animation_div__container}
        >
          <MovingText />
        </Flex>
      </Flex>
      <Flex vertical={false} justify="center" className={Styles.form_div}>
        <Flex vertical={true} className={Styles.form_div__container} gap={12}>
          <Flex vertical={true} className={Styles.while_small}>
            <span className={Styles.form_div__container__text}>
              Download Admit Card{" "}
            </span>
            <span className={Styles.form_div__container__text}>
              ({newsRecords.current.name})
            </span>
          </Flex>
          <AdmitForm />
        </Flex>
      </Flex>
      <Flex vertical={false} justify="center" className={Styles.bottom_div}>
        <Flex
          vertical={false}
          align="center"
          justify="space-between"
          gap={20}
          className={Styles.bottom_div__container}
        >
          <span>
            For the best view use Google Chrome or Mozilla Firefox browser
          </span>
          <span>Copyright © 2024, All Rights Reserved.</span>
        </Flex>
      </Flex>
    </Flex>
  );
}
