import React from "react";
import NavStyles from "./servicePagesParts.module.scss";
import { Flex } from "antd";
import { handlegotoHomePage, handlePdfOpen } from "../../../Helpers/helpers";
import { Link } from "react-router-dom";
export default function Navbar() {
  return (
    <Flex vertical={true} className={NavStyles.navbar_container}>
      <Flex
        vertical={false}
        justify="space-between"
        className={NavStyles.navbar_container__Navbar}
      >
        <Flex vertical={false}>
          <span
            className={NavStyles.navbar_container__Navbar__links}
            style={{ marginLeft: "30px" }}
            onClick={handlegotoHomePage}
          >
            Home
          </span>
          <Link
            to="/result"
            className={NavStyles.navbar_container__Navbar__links}
          >
            Result
          </Link>
          <Link
            to="/Admin-pass"
            className={NavStyles.navbar_container__Navbar__links}
          >
            Admin
          </Link>
        </Flex>
        <span
          className={NavStyles.navbar_container__Navbar__links}
          onClick={() => handlePdfOpen("/my-pdf/Guidelines-PSC.pdf")}
        >
          User Guidline
        </span>
      </Flex>
      <Flex
        vertical={false}
        justify="space-between"
        className={NavStyles.navbar_container__curvedPart}
      >
        <img
          src="/images/nvdotLeftTop.png"
          alt="left-dot"
          width={9}
          height={11}
        />
        <img
          src="/images/nvdotRightTop.png"
          alt="left-dot"
          width={9}
          height={11}
        />
      </Flex>
    </Flex>
  );
}
